<template>
  <header class="header">
    <MaintenanceFilters
      class="filters"
      :categories="categories"
    />

    <div class="previous">
      <a
        v-if="showPrevious"
        :class="previousClass"
        @click.prevent="previous"
      >
        <img
          src="../assets/chevron-left.svg"
          alt="Previous"
        >
      </a>
    </div>

    <transition
      name="title-change"
      mode="out-in"
    >
      <div
        :key="category.title"
        class="header-current"
      >
        <h2 class="title">
          {{ category.title }}
        </h2>
        <h3 class="subtitle">
          {{ category.subtitle }}
        </h3>
      </div>
    </transition>

    <div class="next">
      <a
        v-if="showNext"
        :class="nextClass"
        @click.prevent="next"
      >
        <img
          src="../assets/chevron-right.svg"
          alt="Next"
        >
      </a>
    </div>
  </header>
</template>

<script>
import MaintenanceFilters from './MaintenanceFilters.vue';

export default {
  name: 'MaintenanceCategoryHeader',
  components: { MaintenanceFilters },
  props: {
    category: Object,
    showNext: Boolean,
    showPrevious: Boolean,
    categories: Array,
  },
  data() {
    return {
      highlightNext: false,
      highlightPrevious: false,
    };
  },
  computed: {
    nextClass() {
      return {
        active: this.highlightNext,
      };
    },

    previousClass() {
      return {
        active: this.highlightProvious,
      };
    },
  },
  watch: {
    category() {
      this.highlightNext = false;
      this.highlightPrevious = false;
    },
  },
  methods: {
    next() {
      this.$emit('next');
      this.highlightNext = true;
    },

    previous() {
      this.$emit('previous');
      this.highlightPrevious = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../mixins";

.header {
  @include header-large();
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.header-current {
  flex: 1;
  text-align: center;
}

.filters {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

.previous,
.next {
  display: block;
  width: 1rem;
  padding: 1rem 0.5rem;

  img {
    display: block;
    width: 1rem;
    height: auto;
    transition: all 0.35s;
  }

  .active img {
    opacity: 0.5;
  }
}

.next {
  text-align: right;
}

/**
 * Animations
 */

.title-change-enter-active,
.title-change-leave-active {
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.title-change-enter {
  opacity: 0;
  transform: translate(0, 5px);
}

.title-change-leave-to {
  opacity: 0;
  transform: translate(0, -5px);
}
</style>
