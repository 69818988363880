<template>
  <div
    class="chip"
    :style="customStyle"
    @click.prevent="onClick"
  >
    {{ text }}
    <div
      v-if="hoverNumber"
      :class="hoverClass"
    >
      {{ totalNumber }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChipButton',
  props: {
    text: {
      type: String,
    },
    hoverNumber: {
      type: Number,
      required: false,
    },
    customStyle: {
      type: Object,
      required: false,
    },
    item: {
      type: Object,
      required: false,
    },
    eventName: {
      type: String,
      required: false,
    },
  },
  computed: {
    hoverClass() {
      const number = Number(this.hoverNumber);
      if (number < 10) {
        return 'hover less-10';
      } if (number > 99) {
        return 'hover over-100';
      }
      return 'hover';
    },
    totalNumber() {
      return this.hoverNumber > 99 ? '99+' : this.hoverNumber;
    },
  },
  methods: {
    /* Standard click button
    * @Params:
    * item: {
    *   value: string,
    *   buttonActionParameters: object
    *   }
    * event: string */
    onClick() {
      if (this.item && this.eventName) {
        this.$native.send({
          event: this.eventName,
          command: this.item.value,
          buttonActionParameters: this.item.buttonActionParameters,
          value: this.item.value,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.chip {
  position: relative;
  background-color: $button;
  border-radius: 26px;
  padding: 12px 26px;
  color: $text-tertiary;
  margin-right: 8px;
  font-weight: bold;
  box-sizing: border-box;
  text-align: center;
}

.hover {
  aspect-ratio: 1;
  border-radius: 50%;
  display: grid;
  place-items: center;
  text-align: center;
  background-color: $button-hover;
  color: $text-tertiary;
  position: absolute;
  top: -4px;
  line-height: 24px;
  right: 4px;
  font-size: 10px;
  padding: 0 6px;

  &.less-10 {
    padding: 0 9px;
  }

  &.over-100 {
    padding: 0 4px;
    top: -5px;
    right: 0;
  }
}
</style>
