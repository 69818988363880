<template>
  <div class="vehicle">
    <header class="header">
      <h2 class="title">
        Vehicle details
      </h2>
    </header>

    <ul
      v-if="vehicle"
      class="data-list"
    >
      <li
        v-for="row in data"
        :key="row.label"
        class="data-row"
      >
        <h4
          class="row-label"
          v-html="row.label"
        />
        <span
          class="row-value"
          v-html="row.value"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Vehicle',
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    data() {
      const v = this.vehicle;
      return [
        {
          label: 'Year',
          value: v.year,
        },
        {
          label: 'Make',
          value: v.make,
        },
        {
          label: 'Model',
          value: [
            v.model,
            v.subModel,
          ].filter((vehicle) => !!vehicle).join(' '),
        },
        {
          label: 'Trim',
          value: v.trim,
        },
        {
          label: 'Engine',
          value: v.engine && v.engine.description,
        },
        {
          label: 'Fuel',
          value: v.engine && v.engine.fuel,
        },
        {
          label: 'Drivetrain',
          value: v.driveTrain,
        },
      ].filter((row) => !!row.value);
    },
  },
};
</script>

<style lang="scss">
@import "../mixins";

.vehicle {

}

.header {
  @include header-small();
}

.data-list {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: $surface-light;
}

.data-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  line-height: 1.5rem;
  border-bottom: 1px solid $borders;
}

.row-label {
  flex-shrink: 0;
  margin: 0 2rem 0 0;
  font-size: 1rem;
}

.row-value {
  text-align: right;
  max-width: 65%;
}
</style>
