<template>
  <div class="searchHeader">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'BlueRibbon',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.searchHeader {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $text-tertiray;
  text-transform: uppercase;
  background-color: $ribbon-header;
  font-weight: bold;
  text-transform: capitalize;
}
</style>
