<template>
  <img
    class="icon"
    id="yt-button"
    src="../assets/yt_icon_rgb.png"
    alt="Youtube"
    @click.prevent="onClick"
  >
</template>

<script>
export default {
  name: 'YouTubeButton',
  props: {
    buttonReference: {
      type: String,
    },
  },
  mounted() {
    this.$native.send({
      event: 'youtube:button-enable',
      bounds: this.bounds(),
    });
  },
  beforeDestroy() {
    this.$native.send({
      event: 'youtube:button-disable',
    });
  },
  methods: {
    onClick() {
      this.$native.send({
        event: 'youtube-button:play',
        name: this.buttonReference,
      });
    },
    bounds() {
      const box = document.querySelector('img#yt-button').getBoundingClientRect();
      return `{{${box.x},${box.y}}, {${box.right},${box.bottom}}}`;
    },
  },
};
</script>

<style scoped>
.icon {
  height: 30px;
  width: 40px;
  margin-left: auto;
}
</style>
