<template>
  <div class="section-table-container">
    <div class="table-title">
      {{ this.title }}
    </div>

    <div
      class="table-row"
      :key="row.text + index + 'row'"
      v-for="(row, index) in this.table"
    >
      <div class="table-label">
        {{ row.text }}
      </div>

      <div class="table-value">
        <span
          :key="row.text + value + 'value'"
          v-for="value in row.value"
        >
          {{ value }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SummaryTable',
  props: {
    table: Array,
    title: String,
  },
};
</script>

<style scoped lang="scss">
.section-table-container {
  display: flex;
  flex-direction: column;
  background-color: $surface-light;
  padding: 10px;

  .table-title {
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .table-row {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding-bottom: 6px;

    &:last-child {
      border: none;
    }

    .table-label {
      font-weight: bold;
      margin-right: 10px;
    }

    .table-value {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
