<template>
  <div
    :class="elClass"
    @click.prevent="$emit('selectStep', item)"
  >
    <h4
      class="label"
      v-html="`${number}. ${item.name}`"
    />
    <div
      class="value"
      v-html="item.notes"
    />

    <div class="actions">
      <span
        v-if="item.isSevere"
        class="severe-alert"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M0 0h24v24H0z"
            fill="none"
          />
          <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
        </svg>
        <span>Severe</span>
      </span>

      <a
        v-if="item.procedure"
        class="procedure-button"
        @click.stop.prevent="$emit('showProcedure', item)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <!--eslint-disable-next-line max-len -->
          <path d="M2 17h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1 3h1.8L2 13.1v.9h3v-1H3.2L5 10.9V10H2v1zm5-6v2h14V5H7zm0 14h14v-2H7v2zm0-6h14v-2H7v2z" />
          <path
            d="M0 0h24v24H0z"
            fill="none"
          />
        </svg>
        <span>Procedure</span>
      </a>

      <a
        v-if="item.location"
        class="location-button"
        @click.stop.prevent="$emit('showLocation', item)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M0 0h24v24H0z"
            fill="none"
          />
          <!--eslint-disable-next-line max-len -->
          <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
        </svg>
        <span>location</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaintenanceItemRow',
  props: {
    item: Object,
    selected: Boolean,
  },
  computed: {
    elClass() {
      return {
        step: true,
        [`step-${this.number}`]: !!this.number,
        'step-active': this.selected,
      };
    },

    number() {
      return this.item.metadata.categoryLineNumber;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../mixins";

.step {
  position: relative;
  padding: 1rem;
  line-height: 1.4;
  border-bottom: 1px solid $borders;
  transition: padding 0.3s ease, opacity 0.3s;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0.75rem, -50%);
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    background: $hyper-link;
    opacity: 0;
    transition: all 0.3s;
  }
}

.step-active {
  padding-left: 1.75rem;

  &:after {
    opacity: 1;
  }
}

.label {
  margin: 0 2rem 0.25rem 0;
  font-size: 1rem;
}

.value {
  font-size: 0.875rem;
}

.actions {
  margin-top: 0.75rem;
  margin-left: -0.25rem;
}

.procedure-button,
.location-button,
.severe-alert {
  display: inline-block;
  padding: 0.25rem;
  margin-right: 1rem;
  line-height: 0;
  color: $hyper-link;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    fill: $hyper-link;
    vertical-align: middle;
  }

  span {
    display: inline-block;
    margin-left: 0.5rem;
    font-weight: 500;
    font-size: 0.875rem;
  }
}

.severe-alert {
  color: $danger;

  svg {
    fill: $danger;
  }
}
</style>
