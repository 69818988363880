<template>
  <section class="section">
    <div
      v-if="!isTextSearch"
      class="section-header"
    >
      <h4 class="section-title">
        {{ section.categoryName }}
      </h4>
      <p class="section-description">
        {{ section.description }}
      </p>
    </div>

    <ul class="data-list">
      <template v-for="(row, index) in section.data">
        <template v-if="row">
          <li
            v-if="showSep(row, index, section)"
            :key="row.label + index"
            class="data-row-sep"
          />

          <li
            v-if="isButtonRow(row)"
            :key="row.label + index"
            class="button-row"
          >
            <a
              class="button"
              @click.prevent="onRowClick(row)"
            >
              <span class="label">{{ row.render }}</span>
            </a>
          </li>

          <li
            v-if="isDropdownRow(row)"
            :key="row.label + index"
            class="data-row"
          >
            <DropdownCategory
              class="dropdown-section"
              :dropdown-info="(row)"
            />
          </li>

          <li
            v-if="isDataRow(row)"
            :key="row.label + index"
            class="data-row"
          >
            <!-- last info from dropdown / the deepest level -->
            <h3
              class="row-label"
              v-html="row.label"
            />
            <span
              class="row-value"
              v-html="row.render"
            />
          </li>
        </template>
      </template>
    </ul>
  </section>
</template>

<script>
import DropdownCategory from './DropdownCategory.vue';

export default {
  name: 'SummarySection',
  components: { DropdownCategory },
  props: {
    section: Object,
    isTextSearch: Boolean,
  },
  methods: {
    showSep(row, idx, section) {
      const prev = section.data[idx - 1];
      return prev && row.group !== prev.group;
    },

    isButtonRow(row) {
      return row.type && row.type === 'button';
    },

    isDropdownRow(row) {
      return row.type && row.type === 'dropdown';
    },

    isDataRow(row) {
      // TODO: will a type field be added in future?
      return !row.type || (row.type !== 'button' && row.type !== 'dropdown');
    },

    onRowClick(row) {
      if (row.value) {
        this.$native.send({
          event: 'summary:button',
          command: row.value,
          buttonActionParameters: row.buttonActionParameters,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../mixins";

.section-header {
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: 100%;
  border-bottom: 1px solid $borders;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.section-title {
  margin: 0;
  font-size: 1rem;
  color: $text-primary;
  font-weight: bold;
}

.dropdown-section {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  flex-grow: 1;
  margin: 0;
  font-size: 1rem;
  font-weight: 549;
  color: $text-primary;
}

.section-description {
  display: flex;
  font-size: .8rem;
  font-weight: 549;
  color: $text-primary;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin: 0 1em 0 0;
}

.data-list {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: $surface-light;
}

.data-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  line-height: 1.5rem;
  border-bottom: 1px solid $borders;
  background-color: $surface-light;
}

.data-row-sep {
  height: 0.5rem;
  background-color: $surface;
  border-bottom: 1px solid $borders;
}

.row-label {
  font-weight: 350;
  color: $text-primary;
  flex-shrink: 0;
  flex-basis: 40%;
  margin: 0 2rem 0 0;
  font-size: 1rem;

}

.row-value {
  text-align: right;
  font-weight: 350;
  color: $text-primary;
  flex-shrink: 0;
  flex-basis: 50%;
  font-size: 1rem;
}

.button-row {
  display: flex;
  padding: 0.25rem;
  background-color: $surface;

  &:first-child {
    padding-top: 1.25rem;
  }

  + .data-row {
    border-top: 1px solid $borders;
  }
}

.button {
  @include list-tile();
  width: 100%;
  padding: 22px 16px;

  &:after {
    right: 1.5rem;
  }

  .label {
    font-size: 1rem;
    font-weight: 350;
  }
}
</style>
