<template>
  <div class="recalls">
    <transition-group
      name="page"
      mode="out-in"
    >
      <div
        v-if="!selectedRecall"
        key="recalls"
        class="recall-list"
      >
        <header class="header">
          <h2 class="title">
            Recalls
          </h2>
        </header>

        <a
          v-for="(recall, index) in keyedRecalls"
          :key="recall.key"
          class="recall-card"
          @click.prevent="select(index)"
        >
          <h3 class="recall-title">{{ recall.component }}</h3>
          <h3 class="subtitle">{{ recall.consequence }}</h3>
        </a>
      </div>

      <div
        v-if="selectedRecall"
        key="recall"
        class="recall-details"
      >
        <header class="header">
          <h2 class="recall-title">
            {{ selectedRecall.component }}
          </h2>
          <h3 class="subtitle">
            {{ selectedRecall.consequence }}
          </h3>
        </header>

        <div class="content">
          <section class="meta">
            <div
              v-if="selectedRecall.reportReceivedDate"
              class="meta-item"
            >
              <div class="meta-label">
                Date
              </div>
              <div class="meta-value">
                {{ selectedRecall.reportReceivedDate }}
              </div>
            </div>

            <div
              v-if="selectedRecall.nhtsaCampaignNumber"
              class="meta-item"
            >
              <div class="meta-label">
                NHTSA
              </div>
              <div class="meta-value">
                #{{ selectedRecall.nhtsaCampaignNumber }}
              </div>
            </div>
          </section>

          <hr>

          <section
            v-if="selectedRecall.summary"
            class="section"
          >
            <h3 class="title">
              Description
            </h3>
            <div
              class="body"
              v-html="selectedRecall.summary"
            />
          </section>

          <hr>

          <section
            v-if="selectedRecall.remedy"
            class="section"
          >
            <h3 class="title">
              Remedy
            </h3>
            <div
              class="body"
              v-html="selectedRecall.remedy"
            />
          </section>

          <hr>

          <section
            v-if="selectedRecall.notes"
            class="section"
          >
            <h3 class="title">
              Notes
            </h3>
            <div
              class="body"
              v-html="selectedRecall.notes"
            />
          </section>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
let idCounter = 1;

export default {
  name: 'Recalls',
  props: {
    isMotor: Boolean,
    recalls: Array,
    recallIndex: [String, Number],
  },
  mounted() {
    if (this.isMotor) {
      this.showMotorLogo();
    }
  },
  computed: {
    keyedRecalls() {
      idCounter += 1;
      return (this.recalls || []).map((recall) => ({ key: `recall-${idCounter}`, ...recall }));
    },

    selectedRecall() {
      return this.keyedRecalls[parseInt(this.recallIndex, 10)] || false;
    },
  },
  methods: {
    select(recallIndex) {
      this.$router.push({
        ...this.$route,
        params: { ...this.$route.params, recallIndex },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../mixins";

.recall-title {
  color: #2A2A2A;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
.recall-list {
  width: 100%;
  box-sizing: border-box;
  background-color: $surface;
  word-wrap: break-word;

  .header {
    @include header-small();
  }
}

.recall-card {
  @include list-tile();
  margin: 1rem;

  + .recall-card {
    margin-top: 1rem;
  }
}

.recall-details {
  width: 100%;
  background: $surface-light;
  word-wrap: break-word;

  .header {
    @include header-large();
    .title {
      font-size: 1rem;
      font-weight: bold;
      color: inherit;
    }
  }
}

.content {
  padding: 0 1rem;
}

hr {
  height: 1px;
  margin: 1.5rem 0;
  border: none;
  background-color: rgba(#000, 0.1);
}

.meta {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1.5rem 0;

  .meta-item {
    width: 50%;
  }

  .meta-label {
    font-weight: 500;
  }

  .meta-value {
    margin-top: 0.25rem;
    color: rgba(#000, 0.7);
  }
}

.section {
  margin: 1.5rem 0;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 2rem;
  }

  .title {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 500;
  }

  .body {
    margin-top: 0.25rem;
    color: rgba(#000, 0.7);
  }
}
</style>
