<template>
  <div v-if="!videos || videos.length === 0">
    <h3 class="not-found">
      Somehow no YouTube videos were found. Please go back and try a new search.
    </h3>
  </div>
  <div
    v-else
    class="youtube-video-container"
  >
    <YouTubeActiveVideo
      :video="videos[activeIndex]"
      :need-agreement="needAgreement"
    />
    <div class="youtube-other-videos">
      OTHER VIDEOS
    </div>
    <div class="youtube-cards-container">
      <YouTubeCard
        v-for="(video, idx) in videos"
        :key="idx"
        :video="video"
        :class="elClass(idx)"
        :index="idx"
        @click.native="setActiveIndex(idx)"
      />
    </div>
  </div>
</template>

<script>

import YouTubeCard from '../components/YouTubeCard.vue';
import YouTubeActiveVideo from '../components/YouTubeActiveVideo.vue';

export default {
  name: 'YouTube',
  components: { YouTubeCard, YouTubeActiveVideo },
  props: {
    videos: {
      type: Array,
      required: true,
    },
    needAgreement: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      fullScreen: false,
      activeIndex: 0,
    };
  },
  methods: {
    elClass(index) {
      return index === this.activeIndex ? 'not-show' : '';
    },
    setActiveIndex: function setActiveIndex(index) {
      this.activeIndex = index;
      this.$native.send({ event: 'youtube-player:select', videoId: this.videos[index].id });
    },
  },
};
</script>

<style lang="scss">
.not-show {
  display: none;
}

.youtube-video-container {
  background-color: $surface-light;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  .youtube-cards-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-right: 12px;
    -ms-overflow-style: none;
  }

  .youtube-cards-container::-webkit-scrollbar {
    display: none;
  }

  .youtube-other-videos {
    background-color: $surface;
    padding: 16px 26px 16px 32px;
    margin: 0 0 12px 0;
    border: 1px $borders solid;
    font-family: 'SFPROText-Bold', 'SF UI Display', Helvetica, Arial, sans-serif;
    font-size: 14px;
    text-align: center;
  }

}

.not-found {
  padding: 8px;
  text-align: center;
}
</style>
