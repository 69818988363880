import { xml2js } from 'xml-js';
import ParserContext from './ParserContext';

/**
 * Make figure references a link to the figures modal
 */
function linkFigures(html) {
  const template = '<a href="#figure-$4" data-figure="$4" class="figure-link">$1</a>';
  return html.replace(/((fig[.,]? ?|fig|figure) ?(<em>)? ?([0-9]+) ?(<em>)?)/gi, template);
}

/**
 * Check if this <p> node is a figure caption?
 *
 * - Next node is an image
 * - One child text node
 * - Text similar to "Fig. 1"
 */
function isFigureCaption(node, next) {
  const elems = (node.elements || []);
  const childCount = elems.length;
  const textCount = elems.filter((elem) => elem.type === 'text').length;
  if (childCount !== 1 || textCount !== 1) {
    // Does not have a single text node child
    return false;
  }

  const nextIsImg = next && next.name.toLowerCase() === 'img';
  if (!nextIsImg) {
    // Next node is not an image
    return false;
  }

  // Check the text is describing a figiure
  const { text } = elems[0];
  return !!text.match(/fig[.,]? ?[0-9]*/ig);
}

/**
 * Check if the node is a warning/caution/important note
 * and return the note text with a type
 *
 * Criteria:
 *  - Text content starts with "WARNING: ", etc
 *  - No parent or parent is a procedure / step group (pstepgrp, or MOTOR_Procedure)
 */
function extractNote(node, parent) {
  const tag = (parent.name || '').toLowerCase();
  const validParents = ['motor_procedure', 'pstepgrp'];

  if (parent && validParents.indexOf(tag) === -1) {
    return false;
  }

  // Get the text content of all children
  const text = (function parseChild(child) {
    return (child.text || '') + (child.elements || []).map(parseChild);
  }(node)).trim();

  const searchText = text.toLowerCase();

  if (searchText.indexOf('warning:') === 0) {
    return { type: 'warning', content: text.replace(/warning: ?/i, '') };
  } if (searchText.indexOf('caution:') === 0) {
    return { type: 'caution', content: text.replace(/caution: ?/i, '') };
  } if (searchText.indexOf('important:') === 0) {
    return { type: 'important', content: text.replace(/important: ?/i, '') };
  }

  return false;
}

/**
 * Parse a single node in a Motor xml document
 */
function parse(node, parent, context, isTorque) {
  let html = '';
  let appendChildren = false;
  let end = null;

  const tag = (node.name || node.type || '').toLowerCase();

  const next = parent && parent.elements[parent.elements.indexOf(node) + 1];
  const previous = parent && parent.elements[parent.elements.indexOf(node) - 1];
  const nextName = next ? (next.name || '').toLowerCase() : null;

  const children = (isTorqueProp) => node.elements.map((child) => parse(child, node, context, isTorqueProp)).join('');

  switch (tag) {
    case 'motor_procedure':
      html += `<div class="procedure">${children()}</div>`;
      break;

    case 'motor_content':
      html += `<div class="content">${children()}</div>`;
      break;

    case 'motor_torque_proc':
      html += `<div class="procedure">${children(true)}</div>`;
      break;

    case 'proc':
      html += `<p>${children()}</p>`;
      break;

    case 'torque':
      html += `<span>${children()}</span>`;
      break;

    case 'degrees':
      html += `<span>${children()}</span>`;
      break;

    case 'units':
      html += `<span>${children()}</span>`;
      break;

    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'h5':
      appendChildren = true;
      html += `<${tag}>${node.attributes.title}</${tag}>`;
      break;

    case 'p':
      const note = extractNote(node, parent);
      if (note) {
        context.addNote(note);
        // TODO: should we add notes to the content too?
        // html += `<p class="note ${note.type}">${children()}</p>`
      } else if (isFigureCaption(node, next)) {
        context.addCaption(node.elements[0].text);
      } else {
        html += `<p>${children()}</p>`;
      }
      break;

    case 'img':
      context.addFigure(node.attributes.src);
      break;

    case 'table':
      end = context.startTable();
      html += `<table cellspacing="0" cellpadding="0">${children()}</table>`;
      end();
      break;

    case 'tgroup':
      html += children();
      break;

    case 'colspec':
    case 'spanspec':
      // Has no content
      break;

    case 'thead':
      end = context.startTableHead();
      html += `<thead>${children()}</thead>`;
      end();
      break;

    case 'tbody':
      end = context.startTableBody();
      html += `<tbody>${children()}</tbody>`;
      end();
      break;

    case 'row':
      html += `<tr>${children()}</tr>`;
      break;

    case 'entry':
      const tagName = context.inTableHead ? 'th' : 'td';
      const {
        align, colspan, rowspan, valign,
      } = node.attributes || {};
      html += [
        `<${tagName} `,
        align ? `align="${align}"` : '',
        valign ? `valign="${valign}"` : '',
        colspan ? `colspan="${colspan}"` : '',
        rowspan ? `rowspan="${rowspan}"` : '',
        '>',
        children(),
        `</${tagName}>`,
      ].join('');
      break;

    case 'pstepgrp':
      html += `<div class="step-group">${children()}</div>`;
      break;

    case 'stepgrp':
      end = context.startList();
      html += `<ol class="steps level-1">${children()}</ol>`;
      end();
      break;

    case 'stepgrp2':
      if (!previous || (parent.type || '').toLowerCase() === 'step') {
        // Ususally nested step groups are rendered by the parent step (see "step")
        end = context.startSublist();
        html += `<ul class="substeps">${children()}</ul>`;
        end();
      }
      break;

    case 'step':
      context.addListItem();
      if (context.inSublist) {
        html += `<li>${children()}</li>`;
      } else {
        const stepNumber = isTorque ? context.listIndex : context.listIndex + 1;
        html += `<li class="step step-${stepNumber}" data-step="${stepNumber}" data-step-index="${stepNumber}">`;
        html += `<span class="step-title">Step ${stepNumber}</span>`;
        html += `<div class="step-content">${children()}</div>`;
        if (nextName === 'stepgrp2') {
          // Nested step group
          html += parse(next, node, context);
        }
        html += '</li>';
      }
      break;

    case 'reference':
      if ((parent.name || '').toLowerCase() === 'step' && context.listIndex === 0) {
        html += ` ${node.attributes.desc}`;
      }
      break;

    case 'emph':
      const type = (node.attributes ? node.attributes.type : '').toLowerCase();
      switch (type) {
        case 'bold':
          html += `<strong>${children()}</strong>`;
          break;
        case 'dquote':
          html += `"${children()}"`;
          break;
        default:
          html += `<em>${children()}</em>`;
          break;
      }
      break;

    case 'text':
      html += node.text;
      break;

    default:
      console.error(`Unhandled node type "${node.type}" of name "${node.name}"`, node);
      break;
  }

  if (appendChildren) {
    html += children();
  }

  if (!parent) {
    html = linkFigures(html);
  }

  return html;
}

/**
 * Parse a procedure from a XML string
 *
 * (this can also handle XML nested in JSON objects)
 */
export default function parseProcedureText(xmlText) {
  let text = xmlText;
  // Fix invalid xml for procedure images
  text = text.replace(/<img([\w\W]+?)\/?>/gi, (matching) => {
    let match = matching;
    if (match.slice(-2) === '">') {
      match = match.replace(/>$/, '/>');
    }
    return match;
  });

  // The browser's xml parser often fails to parse motor procedure data
  // xml2js seems to be a lot more forgiving
  const root = xml2js(text);

  if (!root) {
    console.error('Unable to parse XML document. Invalid?');
    return null;
  }

  // There should only ever be one root node... Right?
  const context = new ParserContext();
  const html = parse(root.elements[0], null, context);

  return {
    content: html,
    figures: context.figures,
    notes: context.notes,
  };
}
