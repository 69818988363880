<template>
  <div class="scheduled-maintenance">
    <transition-group
      v-if="scheduledMaintenance"
      name="page"
      mode="out-in"
    >
      <div
        v-if="!selectedCategory"
        key="categories"
        class="categories"
      >
        <MaintenanceHeader
          title="Scheduled Maintenance"
          :categories="categories"
        />

        <MaintenanceCategoryRow
          v-for="category in categories"
          :key="category.category.id"
          :category="category"
          @changeCategory="select"
        />
      </div>

      <div
        v-if="selectedCategory"
        key="maintenance"
        class="maintenance"
      >
        <MaintenanceCategoryHeader
          :category="selectedCategory"
          :show-next="!!nextCategory"
          :show-previous="!!previousCategory"
          :categories="categories"
          @next="next"
          @previous="previous"
        />

        <transition
          name="fade"
          mode="out-in"
        >
          <div
            :key="selectedCategory.category.id"
            class="item-rows"
          >
            <MaintenanceItemRow
              v-for="(row, idx) in selectedCategory.items"
              :key="row.metadata.checkSheetLineNumber"
              :item="row"
              :selected="selectedIdx === idx"
              @selectStep="s => onStepClick(s, idx)"
              @showProcedure="onProcedureClick"
              @showLocation="onLocationClick"
            />
          </div>
        </transition>
      </div>
    </transition-group>

    <FiguresDrawer
      ref="figures"
      :title="figuresTitle"
      :figures="selectedFigures"
      @closed="handleFiguresClosed"
    />
  </div>
</template>

<script>
import FiguresDrawer from '../components/FiguresDrawer.vue';
import MaintenanceHeader from '../components/MaintenanceHeader.vue';
import MaintenanceCategoryHeader from '../components/MaintenanceCategoryHeader.vue';
import MaintenanceCategoryRow from '../components/MaintenanceCategoryRow.vue';
import MaintenanceItemRow from '../components/MaintenanceItemRow.vue';
import { isNumber } from '../utils';

export default {
  name: 'ScheduledMaintenance',
  components: {
    MaintenanceHeader,
    MaintenanceCategoryHeader,
    MaintenanceCategoryRow,
    MaintenanceItemRow,
    FiguresDrawer,
  },
  props: {
    scheduledMaintenance: Array,
    categoryId: [Number, String],
  },
  data() {
    return {
      selectedIdx: null,
      selectedFigures: null,
      figuresTitle: 'Figures',
    };
  },
  computed: {

    categories() {
      const first = this.scheduledMaintenance[0];

      if (first && first.intervalName) {
        throw new Error('Invalid JSON: Old scheduled maintenance data structure');
      }

      return this.scheduledMaintenance.map((category) => {
        let title = category.category.name.match(/(Previous|Current|Next) ?((-|\+)[0-9])?:?/i);
        let subtitle = '';

        if (title && title.length) {
          title = title[0].trim();
          subtitle = category.category.name.slice(title.length).trim();
          title = title.replace(/:$/, '');
        } else {
          title = category.category.name;
        }

        // Try to format numbers within the subtitle
        if (subtitle) {
          const subtitleParts = subtitle.split(' ');
          if (subtitleParts.length > 1 && !Number.isNaN(subtitleParts[0])) {
            subtitle = [
              parseFloat(subtitleParts[0]).toLocaleString(),
              ...subtitleParts.slice(1),
            ].join(' ').replace(/\.$/, '');
          }
        }

        return { ...category, title, subtitle };
      });
    },

    /**
     * Try to find the selected category based on the categoryId prop
     *
     * This may be an ID or to might be a slug like "previous-2".
     */
    selectedCategory() {
      if (!this.categoryId) {
        return false;
      }

      if (isNumber(this.categoryId)) {
        // Category ID is actually a category id
        const id = parseInt(this.categoryId, 10);
        return this.categories.find((c) => c.category.id === id);
      }

      // Try to match slugs in the format of; current, next, next+1, previous-2
      const slug = this.categoryId.toLowerCase().trim().replace(/ (-|\+)/, '$1');
      return this.categories.find((category) => {
        const lookup = category.title.toLowerCase().trim().replace(/ (-|\+)/, '$1');
        return slug === lookup;
      });
    },

    currentIndex() {
      return this.categories.indexOf(this.selectedCategory);
    },

    previousCategory() {
      if (this.selectedCategory) {
        return this.categories[this.currentIndex - 1];
      }
      return 0;
    },

    nextCategory() {
      if (this.selectedCategory) {
        return this.categories[this.currentIndex + 1];
      }
      return this.categories.length;
    },
  },
  mounted() {
    this.showMotorLogo();
    this.$native.on('step:focus', this.handleStepFocus);
    this.$native.on('figures:show', this.handleShowFigures);
  },
  beforeDestroy() {
    this.hideMotorLogo();
    this.$native.off('step:focus', this.handleStepFocus);
    this.$native.off('figures:show', this.handleShowFigures);
  },
  methods: {
    select(category) {
      this.sendChangeCategoryEvent(category);

      this.$router.push({
        ...this.$route,
        params: {
          ...this.$route.params,
          categoryId: category.category.id,
        },
      });
    },

    next() {
      this.sendChangeCategoryEvent(this.nextCategory);
    },

    previous() {
      this.sendChangeCategoryEvent(this.previousCategory);
    },

    sendChangeCategoryEvent(category) {
      this.$native.send({
        event: 'maintenance:category',
        categoryIndex: this.categories.indexOf(category),
        categoryId: category.category.id,
        categoryName: category.category.name,
      });
    },

    selectStep(idx) {
      if (idx < 0) {
        this.selectedIdx = null;
        return;
      }

      this.selectedIdx = idx;
      const step = this.$el.querySelector(`.step-${idx + 1}`);

      if (step) {
        step.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    },

    onStepClick(step) {
      this.$native.send({
        event: 'maintenance:step',
        checkSheetLineNumber: step.metadata.checkSheetLineNumber,
        categoryLineNumber: step.metadata.categoryLineNumber,
      });
    },

    onLocationClick(step) {
      this.$native.send({
        event: 'maintenance:location-figure',
        checkSheetLineNumber: step.metadata.checkSheetLineNumber,
        categoryLineNumber: step.metadata.categoryLineNumber,
      });
    },

    onProcedureClick(step) {
      this.$native.send({
        event: 'maintenance:procedure',
        checkSheetLineNumber: step.metadata.checkSheetLineNumber,
        categoryLineNumber: step.metadata.categoryLineNumber,
      });
    },

    handleStepFocus(data) {
      let category;
      const line = data.csLine || data.checkSheetLineNumber;
      const categoryLine = data.categoryLine || data.categoryLineNumber || data.number;

      if (!this.selectedCategory) {
        return;
      }

      if (line) {
        category = this.selectedCategory.items.find((i) => (
          i.metadata.checkSheetLineNumber || i.csLine) === line);
      } else if (categoryLine) {
        category = this.selectedCategory.items.find((i) => (
          i.categoryLine || i.metadata.categoryLineNumber) === categoryLine);
      }

      const idx = this.selectedCategory.items.indexOf(category);
      this.selectStep(idx);
    },

    handleShowFigures(data) {
      this.selectedFigures = data.componentLocation;
      this.figuresTitle = this.selectedFigures && this.selectedFigures[0].partName;
    },

    handleFiguresClosed() {
      this.selectedFigures = null;
    },
  },
};
</script>

<style lang="scss">
@import "../mixins";

.scheduled-maintenance {
}

.categories {
  width: 100%;
  box-sizing: border-box;
  background-color: $surface;
}

.maintenance {
  width: 100%;
}

.item-rows {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: $surface-light;
}

/**
 * Animations
 */

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}

.fade-enter-active {
  transition-delay: 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
