/**
 * ParserContext keeps track of non html content entities
 * (Eg, figures and notes)
 *
 * Also used to keep track of relevant parser context when traversing.
 */
export default class ParserContext {
  constructor() {
    this.notes = [];
    this.figures = [];

    // State of the current table being parsed
    this.table = null;
    this.inTable = false;
    this.inTableHead = false;
    this.inTableBody = false;

    // State of the current list/step group being parsed
    this.inList = false;
    this.inSublist = false;
    this.listIndex = null;
    this.sublistIndex = null;
  }

  getData() {
    return {
      notes: this.notes,
      figures: this.figures,
    };
  }

  addNote({ type, content }) {
    this.notes.push({ type, content });
  }

  addCaption(text) {
    this.figures.push({
      label: text,
      image: null,
    });
  }

  addFigure(src) {
    const last = this.figures[this.figures.length - 1];
    if (last && !last.image) {
      last.image = src;
    } else {
      this.figures.push({
        label: '',
        image: src,
      });
    }
  }

  startList() {
    this.inList = true;
    this.listIndex = -1;
    this.sublistIndex = -1;

    return () => {
      this.inList = false;
      this.listIndex = null;
      this.sublistIndex = null;
    };
  }

  startSublist() {
    this.inSublist = true;
    this.sublistIndex = -1;

    return () => {
      this.inSublist = false;
      this.sublistIndex = null;
    };
  }

  addListItem() {
    if (this.inSublist) {
      this.sublistIndex += 1;
    } else {
      this.listIndex += 1;
    }
  }

  startTable() {
    this.inTable = true;
    this.inTableHead = false;
    this.inTableBody = false;

    return () => {
      this.inTable = false;
    };
  }

  startTableHead() {
    this.inTableHead = true;

    return () => {
      this.inTableHead = false;
    };
  }

  startTableBody() {
    this.inTableBody = true;

    return () => {
      this.inTableBody = false;
    };
  }
}
