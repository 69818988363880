/**
 * Generate a UUID
 *
 * Source: https://stackoverflow.com/a/2117523/499631
 */
function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = Math.random() * 16 | 0; const
      // eslint-disable-next-line no-bitwise,no-mixed-operators
      v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export default class MessageRouter {
  /**
   * MessageRouter allow Vue routes to be triggered via native messages
   *
   * All data will be saved to the internal store so that we can remember
   * the data required for the screen without reloading.
   *
   * @param {object} router Vue router instance
   * @param {object} nativeInterface NativeInterface instance
   */
  constructor(router, nativeInterface) {
    this.router = router;
    this.nativeInterface = nativeInterface;
    this.store = {};
    this.isInitialRoute = true;
    this._setupRouteHandlers();
    this._setupRouterGuards();
  }

  getPayload(id) {
    return this.store[id] || null;
  }

  _getNamedRoutes() {
    return this.router.options.routes.filter((r) => r.name);
  }

  /**
   * Setup listeners for each named route in the router
   */
  _setupRouteHandlers() {
    this._getNamedRoutes().forEach((route) => {
      this.nativeInterface.on(`load:${route.name}`, (data) => {
        this._handleRoute(route.name, data);
      });
    });
  }

  /**
   * Setup router guard
   *
   * This is where we check for a payload id and then
   * load the route data from the store.
   */
  _setupRouterGuards() {
    // eslint-disable-next-line consistent-return
    this.router.beforeEach((propTo, from, next) => {
      const to = propTo;
      if (to.params.payloadId) {
        const data = this.getPayload(to.params.payloadId);
        if (data) {
          Object.entries(data).forEach(([key, value]) => {
            if (!to.params[key]) {
              to.params[key] = value;
            }
          });
        } else {
          console.error(`Payload not found for id ${to.params.payloadId}`);
          return next({ path: '/', replace: true, query: to.query });
        }
      }
      next();
    });
  }

  _handleRoute(name, data) {
    // Save the message payload to the store
    const payloadId = uuid();
    this.store[payloadId] = data;

    const { current } = this.router.history;
    const fromHome = !current.name || current.name === 'home';
    let func = 'push';

    if (fromHome && this.isInitialRoute) {
      // Replace if we're coming from the home page and it's the first screen
      // (so the user can't go back to it, and the app knows to go to the
      // previous native screen)
      func = 'replace';
    }

    this.isInitialRoute = false;
    this.router[func]({ name, params: { payloadId }, query: current.query });
  }
}
