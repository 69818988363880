<template>
  <section class="section-inner">
    <div>
      <div
        v-if="dropdownSection.emphasis"
        class="row-label-subcategory"
      >
        *** {{ dropdownSection.categoryName }}
      </div>
      <div
        v-else
        class="row-label-subcategory"
      >
        {{ dropdownSection.categoryName }}
      </div>
    </div>

    <ul class="data-list">
      <template v-for="(row, idx) in dropdownSection.data">
        <li
          v-if="showSep(row, idx, dropdownSection)"
          :key="`${dropdownSection.key}_${row.group}_sep`"
          class="data-row-sep"
        />

        <li
          v-if="isButtonRow(row)"
          :key="row.key"
          class="button-row"
        >
          <a
            class="button"
            @click.prevent="onRowClick(row)"
          >
            <span class="label">{{ row.render }}</span>
          </a>
        </li>

        <li
          v-if="isDataRow(row)"
          :key="row.key"
          class="data-row"
        >
          <h3
            class="row-label"
            v-html="row.label"
          />
          <span
            class="row-value"
            v-html="row.render"
          />
        </li>

        <li
          v-if="isPartsRow(row)"
          :key="row.key"
        >
          <Parts
            :part="row"
            class="part-row"
          />
        </li>
      </template>
    </ul>
  </section>
</template>

<script>
import Parts from './Parts.vue';

export default {
  name: 'DropdownSection',
  components: { Parts },
  props: {
    dropdownSection: Object,
  },
  methods: {
    showSep(row, idx, dropdownSection) {
      const prev = dropdownSection.data[idx - 1];
      return prev && row.group !== prev.group;
    },

    isButtonRow(row) {
      return row.type && row.type === 'button';
    },

    isPartsRow(row) {
      return row.type && row.type === 'part';
    },

    isDataRow(row) {
      // TODO: will a type field be added in future?
      return !row.type || (row.type !== 'button' && row.type !== 'dropdown');
    },

    onRowClick(row) {
      if (row.value) {
        this.$native.send({
          event: 'summary:button',
          command: row.value,
          buttonActionParameters: row.buttonActionParameters,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../mixins";

.section-inner {
  display: flex;
  flex-direction: column;
  margin: 0 1rem 0 1rem;
}

.section-title {
  margin: 0;
  font-size: 1rem;
  font-weight: 549;
  color: $text-primary;
}

.data-list {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem 1rem;
  border-top: 1px solid $borders;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: $surface-light;
}

.data-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5rem;
  // border-bottom: 1px solid $borders;
}

.data-row-sep {
  height: 0.5rem;
  background-color: $surface;
  border-bottom: 1px solid $borders;
}

.row-label {
  flex-shrink: 4;

  margin: 0 2rem 0 1em;
  font-size: 0.875rem;
  font-weight: 350;
  color: $surface-dark;
}

.row-label-subcategory {
  padding: 10px 0px 1px 0px;
  font-size: 1rem;
  font-weight: 350;
  color: $text-primary;
}

.row-value {
  flex-shrink: 4;
  text-align: right;

  margin-right: 1em;
  font-size: 0.875rem;
  font-weight: 350;
  color: $surface-dark;
}

.button-row {
  display: flex;
  padding: 0.25rem 1rem;
  background-color: $surface;

  &:first-child {
    padding-top: 1.25rem;
  }
}

.part-row {
  padding: 10px 0px 1px 0px;
}

.button {
  @include list-tile();
  width: 100%;
  padding: 1.25rem 2.25rem 1.25rem 1.25rem;

  &:after {
    right: 1.5rem;
  }

  .label {
    font-size: 1rem;
    font-weight: 600;
    color: $text-secondary;
  }
}
</style>
