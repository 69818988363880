<template>
  <div class="service-summary">
    <header class="header">
      <h2
        class="title"
        v-html="title"
      />
    </header>

    <div class="sections">
      <SummarySection
        v-for="section in sections"
        :key="section.key"
        :section="section"
      />
    </div>

    <FiguresDrawer
      v-if="mutateSummary.media"
      ref="figures"
      :figures="mutateSummary.media"
    />
  </div>
</template>

<script>

import SummarySection from '../components/SummarySection.vue';
import FiguresDrawer from '../components/FiguresDrawer.vue';

let idCounter = 0;

export default {
  name: 'ServiceSummary',
  components: { SummarySection, FiguresDrawer },
  props: {
    title: String,
    summary: Object,
  },
  mounted() {
    this.showMotorLogo();
  },
  data() {
    return {
      isLoading: false,
      mutateSummary: this.summary,
    };
  },
  computed: {
    sections() {
      return (this.mutateSummary.dataCategories || []).map((dataSection) => {
        const section = dataSection;
        const categoryFilter = section.filter;
        idCounter += 1;
        section.key = idCounter;
        section.data = section.data.map((dataRow, idx) => {
          const row = dataRow;
          row.key = `${idCounter}.${idx}`;
          if (!row.filter || !categoryFilter || categoryFilter === row.filter) {
            return row;
          }
          return null;
        });
        return section;
      });
    },

    categoryName() {
      return this.mutateSummary.render.slice(8);
    },

    showNotes() {
      if (this.acceptedNotes || this.disableNotes) {
        return false;
      }
      return this.notes && this.notes.length;
    },
  },
  watch: {
    summary() {
      this.mutateSummary = this.summary;
    },
  },
};
</script>
<style lang="scss">
@import "../mixins";

.header {
  @include header-small();
}
.buttonRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center
}
</style>
