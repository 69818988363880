<template>
  <div class="maintenance-filters">
    <a
      class="filter"
      @click.stop.prevent="toggleType"
    >
      <transition
        name="icon"
        mode="out-in"
      >
        <img
          :key="typeIcon"
          :src="typeIcon"
          alt="Filter"
        >
      </transition>

      <DropdownMenu
        v-model="typeValue"
        :show="showType"
        :options="typeOptions"
        align="right"
        @close="showType = false"
      />
    </a>

    <a
      class="filter"
      @click.stop.prevent="toggleLevel"
    >
      <transition
        name="icon"
        mode="out-in"
      >
        <img
          :key="levelIcon"
          :src="levelIcon"
          alt="Filter"
        >
      </transition>

      <DropdownMenu
        v-model="levelValue"
        :show="showLevel"
        :options="levelOptions"
        align="right"
        @close="showLevel = false"
      />
    </a>
  </div>
</template>

<script>
import DropdownMenu from './DropdownMenu.vue';

import normalIcon from '../assets/filter-normal.svg';
import severeIcon from '../assets/filter-severe.svg';
import mileageIcon from '../assets/filter-mileage.svg';
import timeIcon from '../assets/filter-time.svg';
import indicatorIcon from '../assets/filter-indicator.svg';

const levelOptions = [
  { value: 'severe', label: 'Severe' },
  { value: 'normal', label: 'Normal' },
];

const typeOptions = [
  { value: 'mileage', label: 'Mileage' },
  { value: 'age', label: 'Age' },
  { value: 'indicator', label: 'Indicator' },
];

const levelIcons = {
  normal: normalIcon,
  severe: severeIcon,
};

const typeIcons = {
  mileage: mileageIcon,
  age: timeIcon,
  indicator: indicatorIcon,
};

export default {
  name: 'MaintenanceFilters',
  components: { DropdownMenu },
  props: {
    categories: Array,
  },
  data() {
    const { level, type, severity } = this.$route.params;
    return {
      typeOptions,
      levelOptions,
      typeValue: typeOptions.find((o) => o.value === type) || typeOptions[0],
      levelValue: levelOptions.find((o) => o.value === (level || severity)) || levelOptions[0],
      showType: false,
      showLevel: false,
    };
  },
  computed: {
    typeIcon() {
      return typeIcons[this.typeValue.value];
    },

    levelIcon() {
      return levelIcons[this.levelValue.value];
    },
  },
  watch: {
    typeValue(newValue, oldValue) {
      if (oldValue) {
        this.emitChange();
      }
    },
    levelValue(newValue, oldValue) {
      if (oldValue) {
        this.emitChange();
      }
    },
  },
  methods: {
    toggleType() {
      this.showType = !this.showType;
      this.showLevel = false;
    },

    toggleLevel() {
      this.showLevel = !this.showLevel;
      this.showType = false;
    },

    closeAll() {
      this.showLevel = false;
      this.showType = false;
    },

    emitChange() {
      setTimeout(() => this.closeAll(), 150);
      this.$native.send({
        event: 'maintenance:filter',
        type: this.typeValue.value,
        level: this.levelValue.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  flex-direction: row;

  a {
    position: relative;
    display: block;
    padding: 0.75rem;
    min-height: 1.125rem;
    min-width: 1.125rem;
    line-height: 1;
  }

  img {
    display: inline-block;
    vertical-align: middle;
  }
}

.icon-enter-active,
.icon-leave-active {
  transition: all 0.1s;
}

.icon-leave-to,
.icon-enter {
  transform: scale(0.65);
  opacity: 0;
}
</style>
