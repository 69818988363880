<template>
  <div class="card-container">
    <div class="card-title">
      Youtube &#8226;
      <span class="card-title-sub">{{ video.channelTitle }}</span>
    </div>
    <div
      class="image"
      :style="{ 'background-image': 'url(' + video.thumbnail + ')' }"
    >
      <div
        v-if="formattedDuration"
        class="bottom-right"
      >
        {{ formattedDuration }}
      </div>
    </div>
    <a class="card-description">{{ video.title }}</a>
    <div class="card-date">
      {{ publishedTime }}
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

export default {
  name: 'YouTubeCard',
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  computed: {
    publishedTime() {
      return moment(this.video.publishedAt).format('ll');
    },
    formattedDuration() {
      if (this.video.contentDetails && this.video.contentDetails.duration) {
        return moment.duration(this.video.contentDetails.duration).format('h:mm:ss').padStart(
          4,
          '0:0',
        );
      }
      return false;
    },
  },
  created() {
    momentDurationFormatSetup(moment);
  },
};
</script>

<style scoped lang="scss">
.card-container {
  border: 1px $borders solid;
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 200px;
  width: 175px;
  margin-left: 12px;
}

.card-title {
  font-size: 10px;
  padding: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.card-title-sub {
  color: $surface-dark;
}

.image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 90px;
  width: 100%;
  position: relative;
}

.bottom-right {
  position: absolute;
  bottom: 4px;
  right: 4px;
  padding: 2px;
  background-color: black;
  color: $text-tertiary;
  font-size: 12px;
}

.card-description {
  color: $hyper-link;
  font-size: 11px;
  padding-left: 10px;
  margin-top: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.card-date {
  justify-self: flex-end;
  font-size: 10px;
  color: $surface-dark;
  margin-top: auto;
  margin-bottom: 10px;
  padding-left: 10px;
}

</style>
