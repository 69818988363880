<template>
  <div @click="isOpen = !isOpen">
    <div class="row-label-category">
      <p
        class="row-label"
        v-html="dropdownInfo.label"
      />
      <img
        v-if="!isOpen"
        src="/down-arrow.png"
        style="width:20px"
      >
      <img
        v-else
        src="/up-arrow.png"
        style="width:20px"
      >
    </div>

    <div
      v-show="isOpen"
      class="innerSections"
    >
      <DropdownSection
        v-for="dropdownSection in dropdownSections"
        :key="dropdownSection.key"
        :dropdown-section="dropdownSection"
      />
    </div>
  </div>
</template>

<script>
import DropdownSection from './DropdownSection.vue';

let idCounter = 0;

export default {
  name: 'DropdownCategory',
  components: { DropdownSection },
  props: {
    dropdownInfo: Object,
  },
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    dropdownSections() {
      return (this.dropdownInfo.dataDropdown || []).map((dataDropdownSection) => {
        const dropdownSection = dataDropdownSection;
        idCounter += 1;
        dropdownSection.key = idCounter;
        return dropdownSection;
      });
    },
  },
};
</script>
<style lang="scss" scoped>

.row-label-category {
  flex: 0 0 100%;
  font-size: 1rem;
  font-weight: 350;
  color: $text-primary;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5rem;
}

.innerSections {
  flex-shrink: 4;
  flex-basis: 100%;
  font-size: 0.875rem;
  font-weight: 350;
  color: $surface-dark;
  margin-top: 1rem;
  border: 1px solid $borders;
}
p {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.dropdown-pic {
  height: 50px;
  width: 50px
}
</style>
