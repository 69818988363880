<template>
  <div class="notes">
    <Note
      v-for="(note, idx) in notes"
      :key="`note-${idx}`"
      :note="note"
      :on-figure-click="onFigureClick"
    />

    <div class="actions">
      <a
        class="button"
        @click.prevent="onNext"
      >I understand</a>
    </div>
  </div>
</template>

<script>
import Note from './Note.vue';

export default {
  name: 'Notes',
  components: { Note },
  props: {
    notes: Array,
    onNext: {
      type: Function,
      required: true,
    },
    onFigureClick: Function,
  },
};
</script>

<style lang="scss" scoped>
.notes {
  padding: 0.5rem 0.5rem 4rem;
}

.actions {
  margin: 1.5rem 0;
  text-align: center;
}

.button {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding: 0 50px;
  border: none;
  border-radius: 25px;
  text-align: center;
  font-weight: bold;
  color: $surface-light;
  background-color: $hyper-link;
}
</style>
