<template>
  <div class="detail-header">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'ComponentDetailHeader',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-header {
  min-height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  background-color: $surface-light;
  font-weight: bold;
  border-bottom: 1px solid $surface-dark;
  padding: 8px;
  box-sizing: border-box;
}
</style>
