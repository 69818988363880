<!--Shown for General search queries-->
<template>
  <section>
    <div
      v-if="category.categoryName === 'Part Numbers'"
      class="parts"
    >
      <template v-for="item in items">
        <div
          :key="item.value"
          @click.prevent="onChipClick(item)"
        >
          <ChipButton
            text="Part Numbers"
            :hover-number="item.count"
          />
        </div>
      </template>
    </div>

    <template v-if="category.categoryName === 'Search Results'">
      <h3 class="title">
        {{ category.categoryName }}
      </h3>

      <template v-for="(item, index) in items">
        <a
          v-if="isButton(item)"
          :key="item.value + index"
          class="button"
          @click.prevent="$emit('clickButton', item)"
        >
          <span class="label">{{ item.render }}</span>

          <div
            v-if="hasWarning(item)"
            class="warnings"
          >
            <a
              class="warning"
              @click.prevent.stop="$emit('clickWarning', item)"
            >
              <img
                src="../assets/button-warning.svg"
                alt="Warning"
              >
              {{ item.buttonData.label }}
            </a>
          </div>
        </a>
      </template>
    </template>
  </section>
</template>

<script>
import ChipButton from './ChipButton.vue';

export default {
  name: 'SummaryCategory',
  components: { ChipButton },
  props: {
    category: Object,
  },
  computed: {
    items() {
      return this.category.data;
    },
  },
  methods: {
    isButton(item) {
      return ['button', 'button-warning'].includes(item.type);
    },

    hasWarning(item) {
      return item.buttonData && item.buttonData.type.toLowerCase() === 'warning';
    },

    onChipClick(item) {
      this.$native.send({
        event: 'summary:button',
        command: item.value,
        buttonActionParameters: item.buttonActionParameters,
        value: item.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../mixins";

.title {
  margin: 0 0 0.75rem;
  font-size: 1rem;
  color: $text-primary;
}

.button {
  @include list-tile();
  padding: 1.25rem 2.25rem 1.25rem 1.25rem;

  &:after {
    right: 1.5rem;
  }

  + .button {
    margin-top: 0.3125rem;
  }

  .label {
    font-size: 1rem;
  }
}

.warnings {
  position: absolute;
  right: 3.25rem;
  top: 50%;
  transform: translate(0, -50%);
}

.warning {
  padding: 0.275rem 0.5rem;
  border-radius: 0.25rem;
  line-height: 0.875rem;
  font-size: 0.75rem;
  background-color: rgba($warning, 0.15);
  color: $warning;

  img {
    display: inline-block;
    width: 0.875rem;
    height: auto;
    line-height: 1;
    margin-right: 0.125rem;
    vertical-align: sub;
  }
}

.parts {
  display: flex;
  justify-content: flex-end;
}

</style>
