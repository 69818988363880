<template>
  <div
    class="figures-drawer"
    :class="stateClass"
  >
    <div
      class="figures-header"
      @click.prevent="toggle"
    >
      <h3
        class="figures-title"
        v-html="title"
      />
      <div class="toggle">
        <i class="icon-toggle" />
      </div>
    </div>

    <div
      v-if="isOpen"
      class="figures-content"
    >
      <template v-for="test in tests">
        <a
          :key="buttonName(test)"
          class="button"
          @click.prevent="onButtonClick(test)"
        >
          <span class="label">{{ buttonName(test) }}</span>
        </a>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PinPointTestDrawer',
  props: {
    title: {
      type: String,
      default: 'DTC Supporting Tests',
    },
    tests: {
      type: Array,
      default() {
        return [];
      },
    },
    v2: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    stateClass() {
      return {
        open: this.isOpen,
        'off-screen': !this.isOpen && (!this.tests || !this.tests.length),
      };
    },
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.$emit('opened');
      } else {
        this.$emit('closed');
      }
    },
  },
  mounted() {
    this.$native.on('tests:show', this.open);
    this.$native.on('tests:hide', this.close);
  },
  beforeDestroy() {
    this.$native.off('tests:show', this.open);
    this.$native.off('tests:hide', this.close);
    this.close();
  },
  methods: {
    buttonName(item) {
      if (this.v2) {
        return item.taxon_name;
      }
      return item.render;
    },
    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },

    open() {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    },

    onButtonClick(item) {
      if (this.v2) {
        this.$native.send({
          event: 'v2:button',
          linkToEntity: item,
        });
      } else {
        this.$native.send({
          event: 'dtc:button',
          command: item.value,
          buttonActionParameters: item.buttonActionParameters,
          value: item.value,
        });
      }
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../mixins";

.button {
  @include list-tile();
  padding: 1.25rem 2.25rem 1.25rem 1.25rem;
  color: $text-secondary;

  &:after {
    right: 1.5rem;
  }

  + .button {
    margin-top: 0.3125rem;
  }

  .label {
    font-size: 1rem;
  }
}

.figures-drawer {
  position: fixed;
  bottom: 60px;
  right: 3px;
  left: 3px;
  height: 100%;
  transform: translate(0, 100%);
  display: flex;
  flex-direction: column;
  background-color: $surface-light;
  z-index: 100;
  transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
  color: $text-tertiary;
  border-radius: 1rem 1rem 0 0;

  &.off-screen {
    bottom: 0;
  }

  &.open {
    top: 3px;
    transform: none;
  }
}

.figures-header {
  position: relative;
  height: 60px;
  line-height: 60px;
  padding: 0 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  background: $figures-drawer;
  border-radius: 1rem 1rem 0 0;
  margin-bottom: 16px;

  .toggle {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translate(0, -50%);
  }

  .icon-toggle {
    display: inline-block;
    padding: 4px;
    transform: translate(0, 2px) rotate(-135deg);
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: transform 0.5s;
  }

  .open & .icon-toggle {
    transform: translate(0, -2px) rotate(45deg);
  }
}

.figures-title {
  margin: 0;
  font-size: 1rem;
}

.figures-content {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

</style>
