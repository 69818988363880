<template>
  <div class="procedure-content">
    <section
      class="body"
      v-html="procedureContent"
    />
  </div>
</template>

<script>
const isInt = (val) => val && !Number.isNaN(parseInt(val, 10));

export default {
  name: 'ProcedureContent',
  props: {
    procedureContent: String,
    onFigureClick: {
      type: Function,
      default: () => () => {
      },
    },
  },
  data() {
    return {
      selectedStepIdx: null,
    };
  },
  updated() {
    this.removeClickListeners();
    this.addClickListeners();
  },
  mounted() {
    this.addClickListeners();
  },
  methods: {
    handleFigureClick(link, e) {
      const { figure } = link.dataset;
      e.preventDefault();
      e.stopPropagation();
      if (isInt(figure)) {
        this.onFigureClick(parseInt(figure, 10));
      }
    },
    addClickListeners() {
      this.$el.querySelectorAll('.figure-link').forEach((link) => {
        link.addEventListener('click', this.handleFigureClick.bind(this, link));
      });
    },
    removeClickListeners() {
      this.$el.querySelectorAll('.figure-link').forEach((link) => {
        link.removeEventListener('click', this.handleFigureClick.bind(this, link));
      });
    },
  },
};
</script>

<style lang="scss">
.body {
  padding: 0 1rem;
}

h1, h2, h3 {
  margin: 2rem 0 0.5rem;
}

h1 {
  font-size: 1.125rem;
}

h2 {
  font-size: 1.1rem;
}

h3 {
  font-size: 1rem;
}

p {
  margin: 0 0 1rem;
}

a {
  color: $hyper-link;
  font-weight: 500;
  text-decoration: none;
}

/**
 * Lists / steps
 */
.steps {
  margin: 0;
  padding: 0 2rem 0 0;
  list-style: none;
}

.step {
  position: relative;
  padding: 0.75rem 0;
  cursor: pointer;
  transition: transform 0.2s ease-out;

  &:last-child {
    padding-bottom: 0;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-1.15rem, -50%);
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    background: $hyper-link;
    opacity: 0;
    transition: all 0.3s;
  }
}

.step-muted {
  opacity: 0.6;
}

.step-active {
  transform: translate(1.25rem, 0);

  &:after {
    opacity: 1;
  }
}

.step-title {
  margin: 0 0 0.25rem;
  font-size: 1rem;
  font-weight: bold;
}

.step-content {
  font-size: 1rem;
}

.substeps {
  margin: 1rem 0 0 0;
  padding: 0 0 0 1.5rem;
  list-style: disc;

  li {
    margin: 0.5rem;
  }
}

/**
 * Tables
 */
table {
  margin-left: -1rem;
  margin-right: -1rem;
  width: calc(100% + 2rem);

  th {
    padding: 2rem 0.5rem 1rem;
    border-bottom: 1px solid $borders;
    vertical-align: bottom;
    font-size: 0.875rem;
    font-weight: bold;
    color: $surface-dark;

    &:first-child {
      padding-left: 1rem;
    }

    &:last-child {
      padding-right: 1rem;
    }
  }

  tbody {
    background-color: $surface-light;
  }

  td {
    padding: 1rem 0.5rem 0.5rem;
    border-bottom: 1px solid $borders;
    font-size: 0.875rem;
    vertical-align: top;

    &:first-child {
      padding-left: 1rem;
    }

    &:last-child {
      padding-right: 1rem;
    }

    &.fullwidth {
      padding-top: 2rem;
      font-weight: bold;
    }

    &[colspan] {
      font-weight: 500;
    }

    &[rowspan] {
      border-right: 1px solid $borders;
      font-weight: 500;
    }
  }

  tr:last-child {
    td {
      padding-bottom: 1.5rem;
    }
  }
}
</style>
