<template>
  <div
    class="figures-drawer"
    :class="stateClass"
  >
    <div
      class="figures-header"
      @click.prevent="toggle"
    >
      <h3
        class="figures-title"
        v-html="title"
      />
      <div class="toggle">
        <i class="icon-toggle" />
      </div>
    </div>

    <div
      v-if="isOpen"
      class="figures-content"
    >
      <ImageWithLightbox
        v-for="(figure, idx) in figures"
        :key="figure.url + idx"
        :img-alt="figure.label"
        :other-images="figures"
        :img-url="figure.url"
        :index="figureIndex(idx)"
        :title="figure.label"
        :active-figure="activeNumber"
      />
    </div>
  </div>
</template>

<script>
import ImageWithLightbox from './ImageWithLightbox.vue';

export default {
  name: 'FiguresDraw',
  components: { ImageWithLightbox },
  props: {
    title: {
      type: String,
      default: 'Figures',
    },
    figures: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
      activeNumber: null,
    };
  },
  computed: {
    stateClass() {
      return {
        open: this.isOpen,
        'off-screen': !this.isOpen && (!this.figures || !this.figures.length),
      };
    },
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.$emit('opened');
      } else {
        this.$emit('closed');
      }
    },
  },
  mounted() {
    this.indexOffset = 0;
    this.$native.on('figures:show', this.openFromEvent);
    this.$native.on('figures:hide', this.closeFromEvent);
    this.$native.on('figures:active', this.setActiveFromEvent);
    this.$native.send({
      event: 'figures:drawer-enable',
      bounds: this.bounds,
    });
  },
  beforeDestroy() {
    this.indexOffset = 0;
    this.$native.off('figures:show', this.openFromEvent);
    this.$native.off('figures:hide', this.closeFromEvent);
    this.$native.off('figures:active', this.setActiveFromEvent);
    this.close();
    this.$native.send({
      event: 'figures:drawer-disable',
    });
  },
  methods: {
    bounds() {
      const box = document.querySelector('.figures-drawer').getBoundingClientRect();
      return `{{${box.x},${box.y}}, {${box.right},${box.bottom}}}`;
    },

    isActive(index) {
      return this.activeNumber && this.activeNumber === (index + 1);
    },

    figureIndex(index) {
      if (index > 0) {
        const figure = this.figures[index];
        const prevFigure = this.figures[index - 1];
        if (prevFigure.label === figure.label) {
          this.indexOffset += 1;
        }
      }
      return index - this.indexOffset;
    },

    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },

    open() {
      this.indexOffset = 0;
      this.isOpen = true;
      this.activeNumber = this.activeNumber || 1;
      this.$native.send({ event: 'figures:active', number: this.activeNumber });
    },

    close() {
      this.indexOffset = 0;
      this.isOpen = false;

      if (this.activeNumber) {
        this.activeNumber = null;
        this.$native.send({ event: 'figures:inactive' });
      }
    },

    openFromEvent(event) {
      this.indexOffset = 0;
      this.isOpen = true;
      setTimeout(() => {
        this.activeNumber = parseInt(event.number, 10) || null;
      }, 100);
    },

    closeFromEvent() {
      this.indexOffset = 0;
      this.isOpen = false;
      this.activeNumber = null;
    },

    setActiveFromEvent(event) {
      this.indexOffset = 0;
      this.isOpen = true;
      this.activeNumber = parseInt(event.number, 10) || null;
    },
  },
};
</script>

<style lang="scss">
.figures-drawer {
  position: fixed;
  bottom: 60px;
  right: 3px;
  left: 3px;
  height: 100%;
  transform: translate(0, 100%);
  display: flex;
  flex-direction: column;
  background-color: $surface-light;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  z-index: 100;
  transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
  color: $text-tertiary;

  &.off-screen {
    bottom: 0;
  }

  &.open {
    top: 3px;
    transform: none;
  }
}

.figures-header {
  position: relative;
  height: 60px;
  line-height: 60px;
  padding: 0 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  background: $figures-drawer;
  border-radius: 1rem 1rem 0 0;
  margin-bottom: 16px;

  .toggle {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translate(0, -50%);
  }

  .icon-toggle {
    display: inline-block;
    padding: 4px;
    transform: translate(0, 2px) rotate(-135deg);
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: transform 0.5s;
  }

  .open & .icon-toggle {
    transform: translate(0, -2px) rotate(45deg);
  }
}

.figures-title {
  margin: 0;
  font-size: 1rem;
}

.figures-content {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.figure {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.figure-title {
  margin: 0 0 1rem;
  font-size: 1rem;
}

.figure-image {
  display: block;
  width: 100%;
  height: auto;
}
</style>
