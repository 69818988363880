<template>
  <transition name="dropdown-menu">
    <nav
      v-show="show"
      class="dropdown-menu"
      :class="`align-${align}`"
    >
      <a
        v-for="option in options"
        :key="option.value"
        :class="optionClass(option)"
        @click.stop.prevent="$emit('input', option)"
      >
        {{ option.label }}
      </a>
    </nav>
  </transition>
</template>

<script>
export default {
  name: 'DropdownMenu',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    options: Array,
    value: Object,
    show: Boolean,
    align: {
      type: String,
      default: 'center',
    },
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
  methods: {
    optionClass(option) {
      return {
        active: this.value && this.value.value === option.value,
      };
    },

    close() {
      if (this.show) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
  position: relative;
  min-width: 160px;
  padding: 0 0.5rem;
  border-radius: 5px;
  box-shadow: 0 4px 5px rgba(#000, 0.2);
  background: $surface-light;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    transform: translate3d(0, -100%, 0);
    border: 0.625rem solid transparent;
  }

  &:before {
    border-bottom-color: #000;
    filter: blur(3px);
    opacity: 0.15;
    z-index: -1;
  }

  &:after {
    border-bottom-color: $surface-light;
  }
}
.align-top {
  position: relative;
  margin: auto;
}

.align-center {
  position: absolute;
  left: 50%;
  top: 100%;
  margin-top: 0.25rem;
  transform: translate(-50%, 0);

  &:after,
  &:before {
    left: 50%;
    transform: translate(50%, -100%);
  }
}

.align-right {
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 0.25rem;

  &:after,
  &:before {
    right: 0.75rem;
  }
}

a {
  display: block;
  padding: 0.75rem 2rem;
  border-bottom: 1px solid $surface;
  text-align: center;
  color: $surface-dark;
  font-weight: bold;
  cursor: pointer;

  &.active {
    color: $hyper-link;
  }

  &:last-child {
    border-bottom: none;
  }
}

.dropdown-menu-enter-active,
.dropdown-menu-leave-active {
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.dropdown-menu-enter,
.dropdown-menu-leave-to {
  opacity: 0;
  transform: translate(0, 0.85rem);
}
</style>
