<template>
  <div>
    <div class="data-row">
      <p
        class="row-label"
        v-html="`${part.manufacturer} part# ${part.partNumber}`"
      />
      <span
        class="row-value"
        v-html="part.price"
      />
    </div>
    <div
      class="section-description"
      v-html="`${part.description}`"
    />
  </div>
</template>

<script>

export default {
  name: 'Parts',
  props: {
    part: Object,
  },
};
</script>

<style lang="scss" scoped>
@import "../mixins";

.header {
  @include header-small();
}

.data-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5rem;
  // border-bottom: 1px solid $borders;
}

.row-label {
  flex-shrink: 4;
  flex-basis: 80%;
  font-size: 0.875rem;
  font-weight: 350;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin: 0 2rem 0 1em;
}

.row-value {
  flex-shrink: 4;
  text-align: right;
  flex-basis: 40%;
  font-size: 0.875rem;
  font-weight: 350;
  color: $surface-dark;
  margin-right: 1em;
}

.section-description {
  box-sizing: content-box;
  width: 70%;
  font-size: .8rem;
  font-weight: 549;
  color: $surface-dark;
  padding-left: 1em;
  margin: 0 2rem 0 1em;
}
</style>
