<template>
  <div>
    <template v-if="section.section_type === 'link_to_entity'">
      <div
        class="summary-section-button"
        :key="section.link_to_entity.taxon_name"
        @click="onClickButton(section)"
      >
        {{ section.link_to_entity.taxon_name }}
        <div class="chevron right" />
      </div>
    </template>

    <template v-if="section.section_type === 'link_to_quick_view'">
      <div
        class="summary-section-button"
        :key="section.title"
        @click="onClickQuickView(section)"
      >
        {{ section.title }}
        <div class="chevron right" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SummarySectionLink',
  props: {
    section: Object,
  },
  methods: {
    isLinkButton(value) {
      const linkValues = ['link_to_quick_view', 'link_to_entity'];
      return linkValues.includes(value);
    },
    onClickButton(item) {
      this.$native.send({
        event: 'v2:button',
        linkToEntity: item.link_to_entity,
      });
    },
    onClickQuickView(item) {
      this.$native.send({
        event: 'v2:button',
        quickViewType: item.quick_view_type,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.summary-section-button {
  background-color: $surface-light;
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid $borders;
  border-radius: 5px;
  display: flex;
  text-align: start;

  .chevron {
    margin-left: auto;
  }

  .chevron::before {
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    left: 0.15em;
    position: relative;
    transform: rotate(-45deg);
    width: 0.45em;
  }

  .chevron.right:before {
    left: 0;
    transform: rotate(45deg);
  }

}
</style>
