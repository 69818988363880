<template>
  <div class="header-container">
    <ComponentDetailHeader
      :text="this.title"
    />
    <div class="pdfHeader">
      <div class="navigation">
        <button
          class="toolbarButton pageUp"
          title="Previous Page"
          id="previous"
          :disabled="this.prevDisabled"
          @click.prevent="previous"
        />
        <button
          class="toolbarButton pageDown"
          title="Next Page"
          id="next"
          :disabled="this.nextDisabled"
          @click.prevent="next"
        />
      </div>
      <input
        type="number"
        id="pageNumber"
        @click="select"
        @change="change"
        class="toolbarField pageNumber"
        :value="this.page"
        size="4"
        min="1"
      >
      <div class="zoom">
        <button
          class="toolbarButton zoomOut"
          title="Zoom Out"
          id="zoomOut"
          @click.prevent="zoomOut"
        />
        <button
          class="toolbarButton zoomIn"
          title="Zoom In"
          id="zoomIn"
          @click.prevent="zoomIn"
        />
      </div>
    </div>
  </div>
</template>
<script>
import * as pdfjsViewer from 'pdfjs-dist/legacy/web/pdf_viewer';
import ComponentDetailHeader from './ComponentDetailHeader.vue';

export default {
  name: 'PDFHeader',
  components: {
    ComponentDetailHeader,
  },
  props: {
    eventBus: {
      type: pdfjsViewer.EventBus,
    },
    numPages: {
      type: Number,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      page: 1,
      prevDisabled: true,
      nextDisabled: false,
    };
  },
  methods: {
    previous() {
      this.page -= 1;
      this.$emit('setPage', this.page);
    },
    next() {
      this.page += 1;
      this.$emit('setPage', this.page);
    },
    select(e) {
      e.target.select();
    },
    change(e) {
      // eslint-disable-next-line no-bitwise
      this.$emit('setPage', e.target.value | 0);
    },
    zoomIn(ticks) {
      this.$emit('zoomIn', ticks);
    },
    zoomOut(ticks) {
      this.$emit('zoomOut', ticks);
    },
  },
  watch: {
    eventBus() {
      if (this.eventBus) {
        this.eventBus.on(
          'pagechanging',
          (evt) => {
            this.page = evt.pageNumber;

            this.prevDisabled = this.page <= 1;
            this.nextDisabled = this.page >= this.numPages;
          },
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header-container {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
}
.pdfHeader {
  background: $surface-dark;
  height: 2rem;
  display: flex;
  position: relative;
  box-shadow: 0 -0.2rem 0.5rem darkgray;
}
.toolbarButton {
  padding: 0;
  margin: 0;
  border-width: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $surface-dark;
}

.toolbarButton.pageUp {
  height: 100%;
  width: 50px;
  background-image: url(/public/pdf_images/icon_previous_page.png);
  background-size: 1rem;
}

.toolbarButton.pageDown {
  height: 100%;
  width: 50px;
  background-image: url(/public/pdf_images/icon_next_page.png);
  background-size: 1rem;
}

.pageNumber {
  -moz-appearance: textfield; /* hides the spinner in moz */
  appearance: textfield;
  width: 4rem;
  text-align: center;
  border: 0;
  background-color: $surface-dark;
  font-size: 1.2rem;
  color: $surface-light;
  background-image: url(/public/pdf_images/div_line_left.png),
    url(/public/pdf_images/div_line_right.png);
  background-repeat: no-repeat;
  background-position: left, right;
  background-size: 0.2rem;
  flex: 1 0 100px;
}

.fileSelect {
  background-color: $surface-dark;
  border: 0;
  color: $surface;
  appearance: textfield;
  background-image: url(/public/pdf_images/div_line_left.png),
    url(/public/pdf_images/div_line_right.png);
  background-repeat: no-repeat;
  background-position: left, right;
  flex: 1 0 100px;
  text-align-last: center;
}

.toolbarButton.zoomOut {
  width: 50px;
  height: 100%;
  background-image: url(/public/pdf_images/icon_zoom_out.png);
  background-size: 1rem;
}

.toolbarButton.zoomIn {
  width: 50px;
  height: 100%;
  background-image: url(/public/pdf_images/icon_zoom_in.png);
  background-size: 1rem;
}

.toolbarButton[disabled] {
  opacity: 0.3;
}

.navigation {
  flex: 1 0 100px;
  display: flex;
  justify-content: space-around;
}

.zoom {
  flex: 1 0 100px;
  display: flex;
  justify-content: space-around;
}
</style>
