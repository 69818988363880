<template>
  <div
    v-if="procedures"
    :class="elClass"
  >
    <div v-if="hasFigures">
      <link
        v-for="figure in this.media"
        :key="figure.url"
        :href="figure.url"
        rel="preload"
        as="image"
      >
    </div>

    <Notes
      v-if="showNotes"
      key="notes"
      :notes="notes"
      :on-next="closeNotes"
      :on-figure-click="onFigureClick"
    />

    <div v-else>
      <header
        v-if="showHeader"
        class="header"
      >
        <div class="header-wrapper">
          <h2
            class="title center"
          >
            {{ title }}
          </h2>
          <div
            class="center"
            :class="showOverlay ? 'button-animation overlay-button' : ''"
            @click="onClickYoutube"
          >
            <YouTubeButton
              :button-reference="taxonName"
            />
          </div>
        </div>
      </header>

      <div class="main">
        <template v-for="(procedure, index) in procedures">
          <ProcedureContent
            v-if="procedure.content"
            :key="procedure + index"
            :procedure-content="procedure.content"
            :on-figure-click="onFigureClick"
          />
        </template>
      </div>
    </div>

    <template v-if="hasFigures">
      <template v-if="contentType === 'specification_procedure'">
        <ImageWithLightbox
          v-for="(figure, idx) in this.media"
          :key="figure.url + idx"
          :img-alt="figure.label"
          :other-images="media"
          :img-url="figure.url"
          :index="idx"
          :title="figure.label"
          :active-figure="idx"
        />
      </template>

      <FiguresDrawer
        v-else
        ref="figures"
        :figures="this.media"
      />
    </template>
  </div>
</template>

<script>
import ProcedureContent from '../components/ProcedureContent.vue';
import FiguresDrawer from '../components/FiguresDrawer.vue';
import { scrollTop } from '../utils';
import YouTubeButton from '../components/YouTubeButton.vue';
import ImageWithLightbox from '../components/ImageWithLightbox.vue';
import Notes from '../components/Notes.vue';

export default {
  name: 'Procedure',
  components: {
    Notes,
    ImageWithLightbox,
    ProcedureContent,
    FiguresDrawer,
    YouTubeButton,
  },
  props: {
    procedures: Array,
    title: {
      type: String,
      default: 'Procedure',
    },
    taxonName: {
      type: String,
      default: '',
    },
    media: Array,
    contentType: String,
  },
  data() {
    return {
      showOverlay: false,
      acceptedNotes: false,
      buttonReference: '',
    };
  },
  computed: {
    elClass() {
      return {
        procedure: true,
        'has-figures': this.hasFigures,
      };
    },

    categoryName() {
      return this.title;
    },

    showNotes() {
      if (this.acceptedNotes || this.disableNotes) {
        return false;
      }
      return this.notes && this.notes.length;
    },

    notes() {
      const notes = this.procedures.map((procedure) => procedure.notes).flat() || [];
      return notes.length ? notes : null;
    },

    showHeader() {
      return this.title;
    },

    hasFigures() {
      return this.media.length;
    },
  },
  watch: {
    showNotes() {
      scrollTop(100);
    },
  },
  mounted() {
    this.showMotorLogo();
    this.$native.on('cautions:show', this.openNotes);
    this.$native.on('cautions:hide', this.closeNotes);
  },
  beforeDestroy() {
    this.hideMotorLogo();
    this.$native.off('cautions:show', this.openNotes);
    this.$native.off('cautions:hide', this.closeNotes);
  },
  methods: {
    openNotes() {
      this.acceptedNotes = false;
    },

    closeNotes() {
      this.acceptedNotes = true;
    },

    onFigureClick(number) {
      if (this.$refs.figures) {
        this.$refs.figures.open(number);
      }
    },

    onClickYoutube() {
      this.showOverlay = true;
    },
  },
};
</script>

<style lang="scss">
@import "../mixins";

.procedure {
  padding-bottom: 90px;
  background-color: $surface-light;
}

.has-figures {
  padding-bottom: 150px;
}

.header {
  @include header-small();
}

.procedure-content {
  &:first-child {
    margin-top: 1.5rem;
  }
}

.procedure-table {

}

.header-wrapper {
  display: flex;
  text-align: center;
  align-items: center;
}

.center {
  margin-left: auto !important;
}

.button-animation {
  animation: gradient 3s infinite linear;
}

.overlay-button {
  opacity: 0.5;
  filter: grayscale(100%);
}

@keyframes gradient {
  from {
    opacity: 0.4;
    filter: grayscale(100%);
  }
  to {
    opacity: 1;
    filter: grayscale(100%);
  }
}
</style>
