<template>
  <div class="summary-section-container">
    <template v-for="(section, index) in this.modifiedSections">
      <div
        class="summary-section-sub-container"
        :key="`section-${section.title}-${index}`">
        <SummarySectionLink
          v-if="isLinkButton(section.section_type)"
          :section="section"
        />

        <template
          v-if="section.section_type === 'table'"
        >
          <SummaryTable
            :key="'table' + index + section.title"
            :table="section.table"
            :title="section.title"
          />
        </template>
        <template
          v-if="section.section_type === 'text'"
        >
          <div class="summary-sub-section">
            <NotesTable
              :title="section.title"
              :text="section.text"
            />
          </div>
        </template>

        <template
          v-if="section.section_type === 'sub_section'"
        >
          <div class="summary-sub-section">
            <div
              class="sub-section-title"
              v-if="section.title"
            >
              {{ section.title.replaceAll(';', ',') }}
            </div>

            <SummarySectionV2
              :key="'section' + index + 'sub' + section.title"
              :sections="section.content"
            />
          </div>
        </template>

        <template
          v-if="section.section_type === 'section'"
        >
          <div
            @click="clickCollapsable(section, index)"
            :class="`summary-section-title ${section.collapsable ?
              'collapsable' : ''} ${!section.toggleCollapse ? 'closed' : ''}`"
          >
            {{ section.title.replaceAll(';', ',') }}

            <template v-if="section.collapsable">
              <div
                class="end"
              >
                <Chevron
                  :direction="!section.toggleCollapse ? 'bottom' : ''"
                />
              </div>
            </template>
          </div>

          <SummarySectionV2
            v-if="section.collapsable && section.toggleCollapse"
            :key="index + section.title + 'inside section'"
            :sections="section.content"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>

import SummaryTable from './SummaryTable.vue';
import SummarySectionLink from './SummarySectionLink.vue';
import Chevron from './Chevron.vue';
import NotesTable from './NotesTable.vue';
import { isLinkButton } from '../utils';

export default {
  name: 'SummarySectionV2',
  components: {
    Chevron, SummarySectionLink, SummaryTable, NotesTable,
  },
  props: {
    sections: Array,
  },
  data() {
    return {
      modifiedSections: this.sections.map((section) => ({
        ...section,
        toggleCollapse: false,
      })),
    };
  },
  methods: {
    isLinkButton,
    clickCollapsable(item, index) {
      const newItem = { ...item };
      newItem.toggleCollapse = !newItem.toggleCollapse;
      this.modifiedSections.splice(index, 1, newItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-container-v2 {
  & > .summary-section-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    .summary-section-sub-container {
      padding: 5px 0;
    }
    & > .summary-section-sub-container {
      & > .summary-sub-section {
        padding: 10px;
      }
      .summary-sub-section {
        background-color: $surface-light;
        display: flex;
        flex-direction: column;
        border-radius: 5px;

        .sub-section-title {
          font-size: 13px;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }

  .summary-sub-section {
    & > .summary-section-container {
      & > .summary-section-sub-container {
        &:not(:last-child) {
          border-bottom: 1px solid $borders;
        }
      }
    }
  }
  .summary-section-title {
    background-color: $surface-light;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding: 16px;

    &.collapsable {
      display: flex;
      align-self: center;
      margin: 0;
    }

    &.closed {
      margin-bottom: 20px;
    }

    .end {
      margin-left: auto;
    }

  }

  .summary-section-item {
    display: flex;
    flex-direction: column;
    background-color: $surface-light;
    border: 1px solid $borders;
    border-radius: 5px;
  }
}
</style>
