<template>
  <div>
    <YouTubeModal
      v-if="showModal"
      @agree="handleAgree"
    />
    <div :class="containerClass()">
      <div :class="elClass()">
        <youtube
          ref="youtubePlayer"
          :video-id="video.id"
          width="100%"
          :height="fullScreen ? '100%' : videoHeight"
        />
      </div>
    </div>
    <div
      v-if="!hideDetails"
      class="description-container"
    >
      <div class="youtube-video-title">
        {{ video.title }}
      </div>
      <div class="description">
        <div>{{ `Youtube &#8226; ${video.channelTitle}` }}</div>
        <div class="published-date">
          {{ videoDescription }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import VueYoutube from 'vue-youtube';
import moment from 'moment';
import YouTubeModal from './YouTubeModal.vue';

const youtubeCodes = {
  PLAY: 1,
  PAUSE: 2,
  BEFORE_PLAY: 3,
  FINISH: 0,
};

export default {
  name: 'YouTubeActiveVideo',
  components: {
    VueYoutube,
    YouTubeModal,
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
    hideDetails: {
      type: Boolean,
      required: false,
    },
    videoHeight: {
      type: Number,
      required: false,
      default: 253,
    },
    needAgreement: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      fullScreen: false,
      hasAgreement: !this.needAgreement,
      showModal: false,
    };
  },
  computed: {
    publishedTime() {
      return moment(this.video.publishedAt, 'YYYYMMDD').fromNow();
    },
    videoDescription() {
      return this.publishedTime;
    },
    player() {
      return this.$refs.youtubePlayer.player;
    },
  },
  mounted() {
    window.addEventListener(
      'orientationchange',
      this.handleOrientationChange,
    );
    this.$native.on('onStateChange', this.onStateChange);
    const orientation = window.screen.orientation.type;
    this.fullScreen = orientation === 'landscape-primary';
  },
  methods: {
    elClass() {
      return this.fullScreen ? 'fullscreen' : '';
    },
    containerClass() {
      return this.fullScreen ? 'youtube-video-container' : '';
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      this.fullScreen = orientation === 'landscape-primary';
    },
    handleAgree() {
      this.showModal = false;
      this.$native.send({ event: 'youtube-player:agreement-accepted' });
      this.hasAgreement = true;
      this.player.playVideo();
    },
    async onStateChange(event) {
      const time = Math.round(await this.player.getCurrentTime());
      switch (event.info) {
        case youtubeCodes.FINISH:
          this.$native.send({ event: 'youtube-player:stop' });
          this.player.loadVideoById(this.video.id);
          this.player.stopVideo();
          break;
        case youtubeCodes.BEFORE_PLAY:
          if (!this.hasAgreement) {
            this.showModal = true;
            this.player.stopVideo();
          }
          break;
        case youtubeCodes.PLAY:
          this.$native.send({ event: 'youtube-player:play', currentTime: time });
          break;
        case youtubeCodes.PAUSE:
          this.$native.send({ event: 'youtube-player:pause', currentTime: time });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.description-container {
  background-color: $surface-light;
  padding: 6px 16px 16px 16px;
}

.youtube-video-title {
  font-size: 16px;
  font-weight: bold;
}

.description {
  font-size: 10px;
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}

.fullscreen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.youtube-video-container {
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
}
</style>
