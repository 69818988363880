<template>
  <div
    class="note"
    :class="note.type"
  >
    <div class="note-header">
      {{ note.type }}
    </div>
    <div
      class="note-content"
      v-html="note.content"
    />
  </div>
</template>

<script>
export default {
  name: 'Note',
  props: {
    note: Object,
  },
};
</script>

<style lang="scss" scoped>
.note {
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background: $surface-light;
}

.note-header {
  padding: 1.15rem 1.5rem 1rem;
  font-weight: bold;
  text-transform: capitalize;
  border-bottom: 1px solid $surface;
}

.note.caution .note-header,
.note.warning .note-header {
  color: $danger;
}

.note.important .note-header {
  color: $hyper-link;
}

.note-content {
  padding: 1rem 1.5rem 1.5rem;

  a {
    color: $hyper-link;
    font-weight: 500;
    text-decoration: none;
  }
}
</style>
