<template>
  <div class="no-results-container">
    <div class="title">
      {{ title }}
    </div>

    <template v-if="noResultsFound.alternativeCategories.length > 0">
      <div class="title margin">
        OTHER CONTENT CATEGORIES
      </div>

      <div class="buttons-container">
        <div
          v-for="button in noResultsFound.alternativeCategories"
          :key="button.render"
          class="button"
        >
          <div @click.prevent="onChipClick(button)">
            <ChipButton
              :hover-number="button.count"
              :custom-style="buttonStyle"
              :text="button.render"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-if="!hideResults">
      <div class="title margin">
        YOUTUBE SEARCH RESULTS
      </div>

      <template v-if="videos.length > 0">
        <div class="youtube-container">
          <YouTubeActiveVideo
            :video="videos[activeIndex]"
            :hide-details="true"
            :video-height="195"
          />
        </div>

        <template v-if="videos.length > 1">
          <div class="other-videos">
            OTHER VIDEOS
          </div>
          <div class="cards-container">
            <YouTubeCard
              v-for="(video, idx) in videos"
              :key="idx"
              :video="video"
              :class="elClass(idx)"
              :index="idx"
              @click.native="setActiveIndex(idx)"
            />
          </div>
        </template>
      </template>

      <div
        v-else
        class="youtube-placeholder"
      />
    </template>

    <!--    <div class="title margin">
      LOOKING FOR SOMETHING ELSE?
    </div>

    <div
      @click="onMailClick()"
      class="email-button-container">
      <ChipButton
        text="Email Customer Support"/>
    </div>-->
  </div>
</template>

<script>
import ChipButton from '../components/ChipButton.vue';
import YouTubeActiveVideo from '../components/YouTubeActiveVideo.vue';
import YouTubeCard from '../components/YouTubeCard.vue';

export default {
  name: 'NoResults',
  components: { YouTubeActiveVideo, ChipButton, YouTubeCard },
  props: {
    noResultsFound: Object,
  },
  data() {
    return {
      buttonStyle: {
        maxWidth: '148px',
        width: '148px',
        padding: '12px 14px',
        fontSize: '11px',
        textTransform: 'capitalize',
        margin: 0,
      },
      videos: [],
      hideResults: false,
      activeIndex: 0,
    };
  },
  computed: {
    title() {
      const { query, categoryType } = this.noResultsFound;
      return `No search results exist in ${categoryType} for "${query}". Please find alternatives below:`;
    },
  },
  watch: {
    noResultsFound() {
      this.sendSearch();
    },
  },
  mounted() {
    this.sendSearch();
    this.$native.on('youtube:videos', this.getVideos);
  },
  beforeDestroy() {
    this.$native.off('youtube:videos', this.getVideos);
  },
  methods: {
    getVideos(data) {
      this.videos = data.videos;
      this.hideResults = data.videos.length === 0;
    },

    onChipClick(item) {
      this.$native.send({
        event: 'summary:button',
        command: item.value,
        buttonActionParameters: item.buttonActionParameters,
        value: item.value,
      });
    },

    /*    onMailClick() {
      this.$native.send({
        event: 'link:click',
        value: 'mailto:help@cytk.io?subject=Support Request from the No Results View'
      });
    }, */

    elClass(index) {
      return index === this.activeIndex ? 'not-show' : '';
    },

    setActiveIndex(index) {
      this.activeIndex = index;
    },

    sendSearch() {
      this.$native.send(
        {
          event: 'youtube:search',
          query: this.noResultsFound.query,
        },
      );
    },
  },
};
</script>

<style  lang="scss">
.no-results-container {
  background-color: $surface;
  padding: 28px 10px 40px 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.title {
  color: $text-secondary;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 19px;

  &.margin {
    margin-top: 30px;
    margin-bottom: 16px;
  }
}

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;

  .button {
    margin-bottom: 12px;
  }
}

.youtube-placeholder {
  width: 100%;
  height: 195px;
  background-color: $surface;
  background-repeat: no-repeat;
  background-position: center;
}

.youtube-container {
  width: 100%;
}

.email-button-container {
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: center;
}

.other-videos {
  background-color: $surface-light;
  padding: 16px 26px 16px 32px;
  margin: 0 0 12px 0;
  border: 1px $borders solid;
  font-family: 'SFPROText-Bold', 'SF UI Display', Helvetica, Arial, sans-serif;
  font-size: 14px;
  width: 100%;
  text-align: center;
}

.cards-container {
  display: flex;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-right: 12px;
  -ms-overflow-style: none;
}

.cards-container::-webkit-scrollbar {
  display: none;
}

.not-show {
  display: none;
}
</style>
