<template>
  <div
    class="pdf-container"
  >
    <PDFHeader
      :title="this.title"
      :event-bus="this.eventBus"
      :num-pages="this.numPages"
      @setPage="setPage"
      @zoomIn="zoomIn"
      @zoomOut="zoomOut"
    />
    <div id="viewerContainer">
      <div
        id="viewer"
        class="pdfViewer"
      />
    </div>
    <Spinner :show="this.showSpinner" />
  </div>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import * as pdfjsViewer from 'pdfjs-dist/legacy/web/pdf_viewer';

import Spinner from './Spinner.vue';
import PDFHeader from './PDFHeader.vue';

pdfjsLib.GlobalWorkerOptions.workerSrc = import('pdfjs-dist/legacy/build/pdf.worker.entry');

export default {
  name: 'PDFDocument',
  components: {
    Spinner,
    PDFHeader,
  },
  props: {
    pdf: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
    },
  },
  mounted() {
    this.showMotorLogo();
    this.initPDF(this.pdf.url);
  },
  beforeDestroy() {
    this.closePDF();
  },
  data() {
    return {
      showSpinner: false,
      eventBus: undefined,
      numPages: 0,
      pdfViewer: undefined,
      pdfLoadingTask: undefined,
      pdfLinkService: undefined,
      pdfHistory: undefined,
      pdfDocument: undefined,
      url: '',
      titleValue: { value: '', label: '' },
      pinchZoomEnabled: false,
    };
  },
  methods: {
    isIdentifix() {
      return process.env.APP_NAME === 'identifix';
    },
    initPDF(url) {
      this.showSpinner = true;
      this.loadPDF(url).then((response) => {
        const { pdfViewer, pdfDocument, eventBus } = response;
        this.pdfViewer = pdfViewer;
        this.pdfDocument = pdfDocument;
        this.numPages = pdfDocument.numPages;
        this.eventBus = eventBus;
        this.eventBus.on('pagesinit', () => {
          this.pdfViewer.currentScaleValue = 'auto';
        });
        if (!this.pinchZoomEnabled) {
          this.pinchZoomEnabled = true;
          this.enablePinchZoom();
        }
      });
    },
    async loadPDF(url) {
      const eventBus = new pdfjsViewer.EventBus();

      const linkService = new pdfjsViewer.PDFLinkService({
        eventBus,
      });

      const container = document.getElementById('viewerContainer');
      const pdfViewer = new pdfjsViewer.PDFViewer({
        container,
        eventBus,
        linkService,
        maxCanvasPixels: 0,
        textLayerMode: 0,
      });
      linkService.setViewer(pdfViewer);
      this.pdfLinkService = linkService;

      const pdfHistory = new pdfjsViewer.PDFHistory({
        eventBus,
        linkService,
      });
      linkService.setHistory(pdfHistory);
      this.pdfHistory = pdfHistory;

      // Loading document.
      const loadingTask = pdfjsLib.getDocument({
        url,
        maxImageSize: 1024 * 1024,
      });

      this.pdfLoadingTask = loadingTask;
      loadingTask.onProgress = (progressData) => {
        this.progress(progressData.loaded / progressData.total);
      };

      return loadingTask.promise.then(
        (pdfDocument) => {
          // Document loaded, specifying document for the viewer.
          pdfViewer.setDocument(pdfDocument);
          linkService.setDocument(pdfDocument);
          pdfHistory.initialize({
            fingerprint: pdfDocument.fingerprints[0],
          });
          return {
            eventBus,
            pdfViewer,
            pdfDocument,
          };
        },
      );
    },
    async closePDF() {
      if (this.pdfLoadingTask) {
        const promise = this.pdfLoadingTask.destroy();
        this.pdfLoadingTask = null;

        if (this.pdfDocument) {
          this.pdfDocument = null;

          this.pdfViewer.setDocument(null);
          this.pdfLinkService.setDocument(null, null);

          if (this.pdfHistory) {
            this.pdfHistory.reset();
          }
        }
        return promise;
      }
      return Promise.resolve(true);
    },
    enablePinchZoom() {
      let startX = 0;
      let startY = 0;
      let initialPinchDistance = 0;
      let pinchScale = 1;
      const viewer = document.getElementById('viewer');
      const container = document.getElementById('viewerContainer');
      const reset = () => {
        startX = 0;
        startY = 0;
        initialPinchDistance = 0;
        pinchScale = 1;
      };
      document.addEventListener('touchstart', (e) => {
        if (e.touches.length > 1) {
          startX = (e.touches[0].pageX + e.touches[1].pageX) / 2;
          startY = (e.touches[0].pageY + e.touches[1].pageY) / 2;
          initialPinchDistance = Math.hypot(
            (e.touches[1].pageX - e.touches[0].pageX),
            (e.touches[1].pageY - e.touches[0].pageY),
          );
        } else {
          initialPinchDistance = 0;
        }
      });
      document.addEventListener('touchmove', (e) => {
        if (initialPinchDistance <= 0 || e.touches.length < 2) { return; }
        if (e.scale !== 1) { e.preventDefault(); }
        const pinchDistance = Math.hypot(
          (e.touches[1].pageX - e.touches[0].pageX),
          (e.touches[1].pageY - e.touches[0].pageY),
        );
        const originX = startX + container.scrollLeft;
        const originY = startY + container.scrollTop;
        pinchScale = pinchDistance / initialPinchDistance;
        viewer.style.transform = `scale(${pinchScale})`;
        viewer.style.transformOrigin = `${originX}px ${originY}px`;
      }, { passive: false });
      document.addEventListener('touchend', () => {
        if (initialPinchDistance <= 0) { return; }
        viewer.style.transform = 'none';
        viewer.style.transformOrigin = 'unset';
        this.pdfViewer.currentScale *= pinchScale;
        const rect = container.getBoundingClientRect();
        const dx = startX - rect.left;
        const dy = startY - rect.top;
        container.scrollLeft += dx * (pinchScale - 1);
        container.scrollTop += dy * (pinchScale - 1);
        reset();
      });
    },
    setPage(p) {
      this.pdfViewer.currentPageNumber = p;
    },
    progress(level) {
      if (level >= 1) {
        this.showSpinner = false;
      }
    },
    zoomIn(ticks) {
      let ticksLeft = ticks;
      let newScale = this.pdfViewer.currentScale;
      do {
        newScale = (newScale * 1.1).toFixed(2);
        newScale = Math.ceil(newScale * 10) / 10;
        newScale = Math.min(5.0, newScale);
        ticksLeft -= 1;
      } while (ticksLeft && newScale < 5.0);
      this.pdfViewer.currentScaleValue = newScale;
    },
    zoomOut(ticks) {
      let ticksLeft = ticks;
      let newScale = this.pdfViewer.currentScale;
      do {
        newScale = (newScale / 1.1).toFixed(2);
        newScale = Math.floor(newScale * 10) / 10;
        newScale = Math.max(0.25, newScale);
        ticksLeft -= 1;
      } while (ticksLeft && newScale > 0.25);
      this.pdfViewer.currentScaleValue = newScale;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "pdfjs-dist/web/pdf_viewer.css";
section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 2rem;
}

.hidden {
  display: none;
}
[hidden] {
  display: none !important;
}

#viewerContainer {
  position: absolute;
  overflow: auto;
  width: 100%;
  inset: 7rem 0 5rem;
}

canvas {
  margin: auto;
  display: block;
}

.pdfViewer .page .loadingIcon {
  width: 2.9rem;
  height: 2.9rem;
  background: url("/public/pdf_images/spinner.png") no-repeat left top / 38rem;
  border: medium none;
  animation: 1s steps(10, end) 0s normal none infinite moveDefault;
  display: block;
  position: absolute;
  top: calc((100% - 2.9rem) / 2);
  left: calc((100% - 2.9rem) / 2);
}

@keyframes moveDefault {
  from {
    background-position: 0 top;
  }

  to {
    background-position: -39rem top;
  }
}

#loadingBar {
  /* Define this variable here, and not in :root, to avoid reflowing the
     entire viewer when updating progress (see issue 15958). */
  --progressBar-percent: 0%;

  position: relative;
  height: 0.6rem;
  background-color: gray;
  border-bottom: 1px solid darkgray;
}

#loadingBar .progress {
  position: absolute;
  left: 0;
  width: 100%;
  transform: scaleX(var(--progressBar-percent));
  transform-origin: 0 0;
  height: 100%;
  background-color: blue;
  overflow: hidden;
  transition: transform 200ms;
}

@keyframes progressIndeterminate {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

#loadingBar.indeterminate .progress {
  transform: none;
  background-color: gray;
  transition: none;
}

#loadingBar.indeterminate .progress .glimmer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5rem;
  background-image: linear-gradient(
    to right,
    gray 0%,
    white 50%,
    gray 100%
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  animation: progressIndeterminate 2s linear infinite;
}
</style>
