<template>
  <a
    class="category"
    @click.prevent="$emit('changeCategory', category)"
  >
    <h3 class="title">{{ category.title }}</h3>
    <h3
      v-if="category.subtitle"
      class="subtitle"
    >{{ category.subtitle }}</h3>
  </a>
</template>

<script>
export default {
  name: 'MaintenanceCategoryRow',
  props: {
    category: Object,
  },
};
</script>

<style lang="scss" scoped>
@import "../mixins";

.category {
  @include list-tile();
  margin: 1rem;

  + .category {
    margin-top: 1rem;
  }
}
</style>
