import { render, staticRenderFns } from "./ChipButton.vue?vue&type=template&id=1cd200be&scoped=true"
import script from "./ChipButton.vue?vue&type=script&lang=js"
export * from "./ChipButton.vue?vue&type=script&lang=js"
import style0 from "./ChipButton.vue?vue&type=style&index=0&id=1cd200be&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cd200be",
  null
  
)

export default component.exports