<template>
  <header class="header">
    <h2
      class="title"
      v-html="title"
    />
    <MaintenanceFilters
      class="filters"
      :categories="categories"
    />
  </header>
</template>

<script>
import MaintenanceFilters from './MaintenanceFilters.vue';

export default {
  name: 'MaintenanceHeader',
  components: { MaintenanceFilters },
  props: {
    title: String,
    categories: Array,
  },
};
</script>

<style lang="scss" scoped>
@import "../mixins";

.header {
  @include header-small();
  position: relative;
  z-index: 10;
}

.filters {
  position: absolute;
  top: 1rem;
  right: 0.75rem;
}
</style>
