<template>
  <div class="container">
    <component
      :is="pageComponent"
      v-bind="$props"
    />
  </div>
</template>

<script>
import GeneralSummary from './GeneralSummary.vue';
import ServiceSummary from './ServiceSummary.vue';
import SpecificationSummary from './SpecificationSummary.vue';

const quickServiceTypes = ['quickService', 'quick-service', 'quick-views'];

export default {
  name: 'SummaryContainer',
  components: { GeneralSummary, ServiceSummary, SpecificationSummary },
  props: {
    title: String,
    summary: Object,
  },
  mounted() {
    this.showMotorLogo();
  },
  computed: {
    pageComponent() {
      if (quickServiceTypes.includes(this.summary.type)) {
        return ServiceSummary;
      } if (this.summary.type === 'queryGroup') {
        return SpecificationSummary;
      }
      return GeneralSummary;
    },
  },
};
</script>

<style lang="scss">
.container {
  margin-bottom: 80px;
}
</style>
