import { render, staticRenderFns } from "./YouTubeButton.vue?vue&type=template&id=c087f676&scoped=true"
import script from "./YouTubeButton.vue?vue&type=script&lang=js"
export * from "./YouTubeButton.vue?vue&type=script&lang=js"
import style0 from "./YouTubeButton.vue?vue&type=style&index=0&id=c087f676&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c087f676",
  null
  
)

export default component.exports