<template>
  <div
    ref="image"
    class="zoom-container"
  >
    <img
      class="zoom-figure-image"
      :src="imgUrl"
      :alt="imgAlt"
    >
    <div
      v-if="legend && legend.length > 0"
      :class="legend.length > 6 ? 'extra-margin' : ''"
      class="legend-container"
    >
      <div
        v-for="(legendItem, index) in legend"
        :key="legendItem.label + index"
      >
        <span class="ref-number">{{ legendItem.ref_number }}</span>
        {{ legendItem.label }}
      </div>
    </div>
  </div>
</template>

<script>

import PinchZoom from 'pinch-zoom-js';

export default {
  name: 'ZoomImage',
  props: {
    imgUrl: {
      type: String,
      required: true,
    },
    imgAlt: {
      type: String,
      required: true,
    },
    legend: {
      type: Array,
    },
  },
  data() {
    return {
      zoomOptions: {
        maxZoom: 5,
        minZoom: 0.2,
        lockDragAxis: false,
      },
    };
  },
  mounted() {
    this.initZoomable();
  },
  beforeDestroy() {
    this.zoom.destroy();
  },
  methods: {
    initZoomable() {
      this.zoom = new PinchZoom(this.$refs.image, this.zoomOptions);
    },
  },
};
</script>

<style lang="scss">
.zoom-container {
  background-color: $surface-light;
  padding-top: 20px;

  .zoom-figure-image {
    background-color: $surface-light;
    width: 100%;
    height: auto;
  }

  .legend-container {
    font-size: 10px;
    background-color: $surface-light;
    color: $text-secondary;
    padding: 2px 6px;

    &.extra-margin {
      margin-bottom: 130px;
    }

  }

  .ref-number {
    font-weight: bold;
    margin-right: 6px;
  }
}

</style>
