import { render, staticRenderFns } from "./YouTubeModal.vue?vue&type=template&id=506c2e9a&scoped=true"
import script from "./YouTubeModal.vue?vue&type=script&lang=js"
export * from "./YouTubeModal.vue?vue&type=script&lang=js"
import style0 from "./YouTubeModal.vue?vue&type=style&index=0&id=506c2e9a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "506c2e9a",
  null
  
)

export default component.exports