const userAgent = window.navigator.userAgent.toLowerCase();
export const isIos = /iphone|ipod|ipad/.test(userAgent);
export const isIosWebview = isIos && !window.navigator.standalone && !/safari/.test(userAgent);
export const isAndroidWebview = !!window.Android;
export const isWebview = isIosWebview || isAndroidWebview;

export function scrollTop(scrollDuration) {
  const scrollStep = -window.scrollY / (scrollDuration / 15);
  const scrollInterval = setInterval(() => {
    if (window.scrollY !== 0) {
      window.scrollBy(0, scrollStep);
    } else {
      clearInterval(scrollInterval);
    }
  }, 15);
}

// eslint-disable-next-line consistent-return
export function setupWebViewJavascriptBridge(callback) {
  if (window.WebViewJavascriptBridge) {
    return callback(window.WebViewJavascriptBridge);
  }
  if (window.WVJBCallbacks) {
    return window.WVJBCallbacks.push(callback);
  }
  window.WVJBCallbacks = [callback];
  const WVJBIframe = document.createElement('iframe');
  WVJBIframe.style.display = 'none';
  WVJBIframe.src = 'https://__bridge_loaded__';
  document.documentElement.appendChild(WVJBIframe);
  setTimeout(() => {
    document.documentElement.removeChild(WVJBIframe);
  }, 0);
}

export function isNumber(n) {
  return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
}

/**
 * Get a renderable base64 image
 *
 * Return url or base64 string, also fixes missing prefixes
 * and common serialization errors.
 */
export function getBase64Image(img) {
  let src = img;
  if (!src) {
    return null;
  }

  if (src.indexOf('://') !== -1 && src.indexOf('data:image') === -1) {
    return src;
  }

  if (src.indexOf('data:image') === -1) {
    src = `data:image/gif;base64,${src}`;
  }

  return src;
}

export function abbreviateNumber(number) {
  const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

  // what tier? (determines SI symbol)
  const tier = Math.log10(Math.abs(number)) / 3 || 0;

  // if zero, we don't need a suffix
  if (tier === 0) {
    return number;
  }

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = 10 ** (tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}

export const recursiveToCamel = (item) => {
  if (Array.isArray(item)) {
    return item.map((el) => recursiveToCamel(el));
  }

  if (typeof item === 'function' || item !== Object(item)) {
    return item;
  }

  return Object.fromEntries(
    Object.entries(item).map(([key, value]) => [
      key.replace(/([-_][a-z])/gi, (c) => c.toUpperCase().replace(/[-_]/g, '')),
      recursiveToCamel(value),
    ]),
  );
};

export const isLinkButton = (value) => {
  const linkValues = ['link_to_quick_view', 'link_to_entity'];
  return linkValues.includes(value);
};
