<template>
  <div
    v-if="summary"
    class="general-summary"
  >
    <BlueRibbon
      v-if="isTextSearch"
      :text="summary.render"
    />
    <div class="categories">
      <Loader v-if="isLoading" />
      <SummaryCategory
        v-for="category in categories"
        v-else
        :key="category.index"
        :category="category"
        @clickButton="onButtonClick"
        @clickWarning="onWarningClick"
      />
    </div>
  </div>
</template>

<script>
import Loader from '../components/Loader.vue';
import SummaryCategory from '../components/SummaryCategory.vue';
import BlueRibbon from '../components/BlueRibbon.vue';

export default {
  name: 'GeneralSummary',
  components: { BlueRibbon, Loader, SummaryCategory },
  props: {
    summary: Object,
  },
  data() {
    return {
      isLoading: false,
      mutateSummary: this.summary,
    };
  },
  computed: {
    categories() {
      const first = 'Part Numbers';
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.mutateSummary.dataCategories.sort(
        (a, b) => {
          if (a.categoryName === first) {
            return -1;
          }
          if (b.categoryName === first) {
            return 1;
          }
          return 0;
        },
      );
    },
    isTextSearch() {
      return this.mutateSummary.dataCategories.length > 0
        && this.mutateSummary.dataCategories[0].data.length > 0
        && this.mutateSummary.dataCategories[0].data[0].buttonActionType === 'searchBar';
    },
  },
  watch: {
    summary() {
      this.mutateSummary = this.summary;
    },
  },
  methods: {
    onButtonClick(item) {
      this.$native.send({
        event: 'summary:button',
        buttonActionParameters: item.buttonActionParameters,
        value: item.value,
        button: item,
      });
    },

    onWarningClick(item) {
      this.$native.send({
        event: 'summary:warning',
        value: item.buttonData.value,
        warning: item.buttonData,
        button: item,
      });
    },
  },
};
</script>

<style lang="scss">
.general-summary {
  position: relative;
  min-height: 100vh;
}

.categories {
  padding: 2.25rem 1rem;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.category + .category {
  margin-top: 2.25rem;
}
</style>
