export default function parseJsonText(dataToParse) {
  let data = dataToParse;
  if (typeof data === typeof {} && data.result) {
    // Data we need is not at the root of the object
    try {
      data = data.result.fulfillment.motordotcom;
    } catch (e) {
      // pass
    }
  }

  if (typeof data === 'string') {
    // Sometimes the data might be wrapped in a <p> tag?
    data = data.trim().replace(/^<p>/, '').replace(/<\/p>$/, '');
  }

  if (!data) {
    return null;
  }

  if (typeof data === typeof {}) {
    return data;
  }

  try {
    // Data might be a json string or an object
    data = JSON.parse(data);
  } catch (e) {
    console.error('Error parsing JSON: ', e);
  }

  return data;
}
