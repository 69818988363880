<template>
  <div :class="chevronClass" />
</template>
<script>

export default {
  name: 'Chevron',
  props: {
    direction: {
      type: String,
      default: '',
    },
  },
  computed: {
    chevronClass() {
      return `chevron ${this.direction}`;
    },
  },
};
</script>

<style scoped lang="scss">
.chevron::before {
  border-style: solid;
  border-width: 0.20em 0.20em 0 0;
  content: '';
  display: inline-block;
  height: 0.65em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.65em;
}

.chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}

.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}

.chevron.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}
</style>
