<template>
  <div>
    <div
      id="app"
    >
      <transition
        name="page"
        mode="out-in"
      >
        <router-view />
      </transition>
    </div>
    <div class="footer-logos">
      <div
        v-if="isIdentifix"
        class="identifix-logo"
      />
      <div
        class="motor-logo"
      />
    </div>
  </div>
</template>

<script>
import { scrollTop } from './utils';

export default {
  name: 'App',
  mounted() {
    scrollTop(100);
  },
  computed: {
    isIdentifix() {
      return process.env.APP_NAME === 'identifix';
    },
  },
};
</script>

<style lang="scss">
html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  color: $text-primary;
  overflow-x: hidden;
  background: $surface;
  height: 100%;
  position: relative;
}

#app {
  font-family: 'SF UI Display', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  min-height: 100vh;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}

.page-enter {
  opacity: 0;
}

.page-leave-to {
  position: absolute;
  width: 100vw;
  opacity: 0;
}

.identifix-logo {
  background: url("./assets/DHMobile-lock-up.svg") no-repeat bottom;
  background-size: contain;
  height: 28px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 60px;
}

.motor-logo {
  display: none;
  background: url("./assets/MOTORDriven.png") no-repeat bottom;
  background-size: contain;
  height: 28px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 60px;
}

.footer-logos {
  height: 60px;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
}
</style>
