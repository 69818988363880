<template>
  <DTC
    :dtc="this.dtc"
    :v2="true"
  />
</template>

<script>
import { recursiveToCamel } from '../utils';

import DTC from './DTC.vue';

export default {
  name: 'DTCV2',
  components: { DTC },
  props: {
    media: Array,
    supporting_tests: Array,
    title: String,
    isMotor: Boolean,
  },
  computed: {
    dtc() {
      return {
        media: recursiveToCamel(this.media),
        dtcSupportingTests: this.supporting_tests,
        title: this.title,
      };
    },
  },
};
</script>
