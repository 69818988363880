<template>
  <div
    v-if="this.show"
    class="spinner"
  />
</template>
<script>
export default {
  name: 'Spinner',
  props: {
    show: Boolean,
  },
};
</script>
<style lang="scss" scoped>
  .spinner {
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
    position: fixed;
    border: 8px solid $surface;
    border-top: 8px solid $hyper-link;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>
