import { setupWebViewJavascriptBridge } from './utils';

export default class NativeInterface {
  constructor() {
    this.listeners = {};
    this.iosBridge = false;
    /* Since the YAPI uses sendMessage too,
     we blacklist the events we don't use for the native app */
    this.youtubeBlackList = ['initialDelivery', 'onReady', 'infoDelivery', 'onPlaybackQualityChange', 'onVolumeChange'];

    // Handle postMessage (https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage)
    window.addEventListener('message', this._handleMessage.bind(this), false);

    // Global method that can be called with a JSON message
    window.receiveMessage = (data) => {
      this._handleMessage({ data });
    };

    // Setup IOS webview bridge (https://github.com/marcuswestin/WebViewJavascriptBridge)
    setupWebViewJavascriptBridge((bridge) => {
      this.iosBridge = bridge;

      bridge.registerHandler('receiveMessage', (data) => {
        this._handleMessage({ data });
      });
    });
  }

  /**
   * Add an event listener
   */
  on(event, handler) {
    this.listeners[event] = this.listeners[event] || [];
    this.listeners[event].push(handler);
  }

  /**
   * Remove an event listener
   */
  off(event, handler) {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter((h) => h !== handler);
    }
  }

  /**
   * Send a message to either the IOS or Android app
   *
   * Prints to console if in development mode
   */
  send(message) {
    const logMessage = (
      process.env.NODE_ENV === 'development'
      || window.location.hostname === 'cytkapp.josephmark.com.au'
    );

    if (logMessage) {
      // eslint-disable-next-line no-console
      console.log('Send native message', message);
    }

    if (window.Android && typeof window.Android.receiveMessage === 'function') {
      window.Android.receiveMessage(JSON.stringify(message));
    }

    if (this.iosBridge && this.iosBridge.callHandler) {
      this.iosBridge.callHandler('receiveMessage', message, () => {});
    }
  }

  /**
   * Create a Vue plugin to expose the native interface to all components
   */
  getPlugin() {
    const nativeInterface = this;
    return {
      install(Vue) {
        Vue.mixin({
          beforeCreate() {
            this.$native = nativeInterface;
          },
        });
      },
    };
  }

  _handleMessage(e) {
    // Check if this is a webpack message
    if (e.type === 'webpackInvalid' || (typeof e.data === 'string' && e.data.indexOf('webpack') === 0)) {
      return;
    }

    const data = this._parseEventData(e);
    this._invokeListeners(data);
  }

  // eslint-disable-next-line class-methods-use-this
  _parseEventData(e) {
    let data;

    try {
      if (typeof e.data === typeof {}) {
        data = e.data;
      } else {
        data = JSON.parse(e.data);
      }
    } catch (error) {
      console.error('Error parsing message JSON: ', error);
    }

    return data;
  }

  _invokeListeners(data) {
    if (!data || !data.event || this.youtubeBlackList.includes(data.event)) {
      return;
    }

    const funcs = this.listeners[data.event];

    if (funcs && funcs.length) {
      funcs.forEach((func) => func(data));
    } else {
      console.error(`No event listeners for "${data.event}"`);
    }
  }
}
