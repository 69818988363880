<template>
  <div class="notes-table-container">
    <div
      class="notes-table-row"
    >
      <div class="notes-table-label">
        Notes
      </div>
      <div class="notes-table-value">
        <span
          :key="text + index"
          v-for="(value, index) in text"
        >
          {{ value }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotesTable',
  props: {
    text: Array,
    title: String,
    parentType: String,
  },
};
</script>

<style scoped lang="scss">
.notes-table-container {
  background-color: $surface-light;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;

  .notes-table-row {
    font-size: 12px;
    display: flex;

    .notes-table-label {
      flex: 0 0 60px;
      font-weight: bold;
    }
    .notes-table-value {
      flex: 0 1 100%;
    }
  }
}
</style>
