<template>
  <div class="modal-container">
    <div class="modal-overlay" />
    <div class="modal-content">
      <img
        :src="`/${infoIcon}`"
        :key="infoIcon"
        alt="Info"
        class="info-img"
      >
      <div>
        YouTube content has not been verified by Identifix.
        Access to and reliance on such content is solely under
        <a
          href="#"
          @click.prevent="navToEula"
        >
          YouTube's Terms of Service
        </a> and the user's responsibility.
      </div>
      <button
        class="u-button"
        @click.prevent="ok"
      >
        I UNDERSTAND
      </button>
    </div>
  </div>
</template>
<script>
import icon from '../assets/info.svg';

export default {
  name: 'YouTubeModal',
  methods: {
    ok() {
      this.$emit('agree');
    },
    navToEula() {
      this.$native.send({ event: 'youtube-player:show-terms-of-service' });
    },
  },
  computed: {
    infoIcon() {
      return icon;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-container {
  opacity: 1.0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.modal-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: RGBA(0, 0, 0, 0.6);
}
.modal-content {
  border-radius: 6px;
  position: relative;
  padding: 2em;
  top: 2em;
  margin: auto;
  left: 0;
  right: 0;
  background: $surface-light;
  height: 70%;
  text-align: center;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.info-img {
  width: 50px;
  margin: 0 auto;
}
.u-button {
  background-color: $button;
  color: $text-tertiary;
  border-radius: 6px;
  padding: 0.5em 1em;
  margin: 0 auto;
}
</style>
