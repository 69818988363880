import Vue from 'vue';
import VueRouter from 'vue-router';
import VueYoutube from 'vue-youtube';
import smoothscroll from 'smoothscroll-polyfill';

import App from './App.vue';
import NativeInterface from './NativeInterface';
import MessageRouter from './MessageRouter';
import Procedure from './pages/Procedure.vue';
import Vehicle from './pages/Vehicle.vue';
import Recalls from './pages/Recalls.vue';
import ScheduledMaintenance from './pages/ScheduledMaintenance.vue';
import SummaryContainer from './pages/SummaryContainer.vue';
import Figures from './pages/Figures.vue';
import parseProcedureText from './parser/motorXmlParser';
import parseJsonText from './parser/jsonParser';
import { isWebview, recursiveToCamel } from './utils';
import YouTube from './pages/YouTube.vue';
import Wires from './pages/Wires.vue';
import NoResults from './pages/NoResults.vue';
import DTC from './pages/DTC.vue';
import DTCPinpointTest from './pages/DTCPinpointTest.vue';
import SummaryV2 from './pages/SummaryV2.vue';
import DTCV2 from './pages/DTCV2.vue';

Vue.config.productionTip = false;

smoothscroll.polyfill();

Vue.use(VueRouter);
Vue.use(VueYoutube);

Vue.mixin({
  methods: {
    showMotorLogo: () => {
      const motorLogo = document.querySelector('.motor-logo');
      if (motorLogo) {
        motorLogo.style.display = 'block';
      }
    },
    hideMotorLogo: () => {
      const motorLogo = document.querySelector('.motor-logo');
      if (motorLogo) {
        motorLogo.style.display = 'none';
      }
    },
  },
});

const routes = [
  {
    name: 'home',
    path: '/',
    component: {
      render: (createElement) => createElement('div'),
    },
  },
  {
    name: 'procedure',
    path: '/procedure/:payloadId',
    component: Procedure,
    props: (route) => {
      const proceduresXML = parseJsonText(route.params.procedure);
      return ({
        procedures: proceduresXML.procedures.map((unparsed) => parseProcedureText(unparsed)),
        media: proceduresXML.media || parseJsonText(route.params.media) || parseJsonText([]),
        title: route.params.title || proceduresXML.title,
        taxonName: route.params.taxon_name || proceduresXML.taxon_name,
        contentType: proceduresXML.sub_type,
      });
    },
  },
  {
    name: 'vehicle',
    path: '/vehicle/:payloadId',
    component: Vehicle,
    props: (route) => ({
      vehicle: parseJsonText(route.params.vehicle),
    }),
  },
  {
    name: 'recall',
    path: '/recall/:payloadId/:recallIndex?',
    component: Recalls,
    props: (route) => ({
      isMotor: true,
      recalls: parseJsonText(route.params.recall || route.params.recalls),
      recallIndex: route.params.recallIndex,
    }),
  },
  {
    name: 'recall-v2',
    path: '/recall-v2/:payloadId/:recallIndex?',
    component: Recalls,
    props: (route) => ({
      isMotor: false,
      recalls: parseJsonText(route.params.recall_report).map((recall) => recursiveToCamel(recall)),
      recallIndex: route.params.recallIndex,
    }),
  },
  {
    name: 'scheduled-maintenance',
    path: '/scheduled-maintenance/:payloadId/:categoryId?',
    component: ScheduledMaintenance,
    props: (route) => ({
      scheduledMaintenance: parseJsonText(route.params.scheduledMaintenance),
      categoryId: route.params.categoryId,
      level: route.params.severity || route.params.level,
      type: route.params.type,
    }),
  },
  {
    name: 'summary',
    path: '/summary/:payloadId',
    alias: [
      '/service-summary/:payloadId',
      '/procedure-summary/:payloadId',
    ],
    component: SummaryContainer,
    props: (route) => {
      let { summary, title } = route.params;
      const { serviceSummary } = route.params;

      summary = parseJsonText(summary || serviceSummary);
      summary = summary.summaryData || summary.summary || summary;
      if (Array.isArray(summary)) {
        summary = { dataCategories: summary };
      }

      title = title || summary.render;

      return { title, summary };
    },
  },
  {
    name: 'summary-v2',
    path: '/summary-v2/:payloadId',
    component: SummaryV2,
    props: (route) => {
      const { cytk_content_data_type: cytkContentDataType, ...params } = route.params.summary;
      const summary = { cytkContentDataType, ...params };
      return summary;
    },
  },
  {
    name: 'figures',
    path: '/figures/:payloadId',
    component: Figures,
    props: (route) => ({
      title: route.params.title,
      figures: parseJsonText(route.params.media || route.params.componentLocation || []),
    }),
  },
  {
    name: 'youtube',
    path: '/youtube/:payloadId',
    component: YouTube,
    props: (route) => ({
      videos: parseJsonText(route.params.results),
      needAgreement: /true/.test(route.params['need-agreement']),
    }),
  },
  {
    name: 'wires',
    path: '/wires/:payloadId',
    component: Wires,
    props: (route) => ({
      wires: parseJsonText(route.params.wires),
    }),
  },
  {
    name: 'no-results',
    path: '/no-results/:payloadId',
    component: NoResults,
    props: (route) => ({
      noResultsFound: parseJsonText(route.params.noResultsFound),
    }),
  },
  {
    name: 'dtc',
    path: '/dtc/:payloadId',
    component: DTC,
    props: (route) => ({
      dtc: parseJsonText(route.params.dtc),
    }),
  },
  {
    name: 'dtc-v2',
    path: '/dtc-v2/:payloadId',
    component: DTCV2,
    props: (route) => ({
      title: route.params.title,
      media: parseJsonText(route.params.media),
      supporting_tests: parseJsonText(route.params.supporting_tests),
    }),
  },
  {
    name: 'dtc-pinpoint-test',
    path: '/dtc-pinpoint-test/:payloadId',
    component: DTCPinpointTest,
    props: (route) => ({
      dtcPinpointTest: parseJsonText(route.params.dtcPinpointTest),
    }),
  },
];

const router = new VueRouter({
  mode: isWebview ? 'hash' : 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

const nativeInterface = new NativeInterface();
Vue.use(nativeInterface.getPlugin());

// eslint-disable-next-line no-new
new MessageRouter(router, nativeInterface);

new Vue({
  router,
  render: (h) => h(App),
  created() {
    const { fontSize } = this.$route.query;

    if (fontSize) {
      window.document.documentElement.style.fontSize = `${parseInt(fontSize, 10)}px`;
    }
  },
}).$mount('#app');
